<!-- Filter Datatables -->
<div class="card">
  <div class="card-content">
    <div class="card-body" style="overflow-x: auto">
      <div class="col-md-12 input-group mb-3 mt-2">
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          aria-label="Search..."
          aria-describedby="basic-addon2"
          (change)="search($event)"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="input-group-text btn btn-primary"
            id="basic-addon2"
          >
            <i class="ft-search mr-1 align-middle"></i>
            <span class="align-middle">Search</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <section id="ngx-datatable-filter"> -->
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body" style="overflow-x: auto">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Title</th>
              <th scope="col">Organizer</th>
              <th scope="col">Organizer Phone</th>
              <th scope="col">State</th>
              <th scope="col">Price</th>
              <th scope="col">Featured</th>
              <th scope="col">Cancel</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let event of events">
              <td>{{ event?.id }}</td>
              <td>
                <a [routerLink]="['/pages/events', event?.id]">
                  {{ event?.title }}
                </a>
              </td>
              <td>
                {{ event.organizer.first_name }} {{ event.organizer.last_name }}
              </td>
              <td>{{ event.organizer.country_code }} {{ event.organizer.mobile }}</td>
              <td>
                {{ event.state }}
              </td>
              <td>
                {{ event.price }}
              </td>
              <td>
                <ui-switch
                  size="small"
                  color="#975AFF"
                  [checked]="event.isFeatured"
                  (valueChange)="onEventFeaturedValueChange($event, event.id)"
                ></ui-switch>
              </td>
              <td>
                <ui-switch
                  size="small"
                  color="#975AFF"
                  [disabled]="event.cancelled_at !== null"
                  [checked]="event.cancelled_at ? true : false"
                  (valueChange)="onEventCancelValueChange(event.id)"
                ></ui-switch>
              </td>
            </tr>
            </tbody>
          </table>

          <div>
            <ngb-pagination
              [collectionSize]="pageSize"
              (pageChange)="pageChange($event)"
              [(page)]="page"
              pageSize="10"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="true"
              [boundaryLinks]="false"
            ></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </section> -->

<ngx-spinner
  type="ball-triangle-path"
  size="medium"
  bdColor="rgba(0, 0, 0, 0.8)"
  color="#fff"
  fullScreen="true"
></ngx-spinner>
