<!-- Invoice template starts -->
<div class="row">
  <div class="col-12">
    <div class="content-header">Invoice Summary ({{organizerType.toLocaleUpperCase()}})</div>
  </div>
</div>
<section class="invoice-template">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-content p-3">
          <div id="invoice-template" class="card-body pb-0">
            <!-- Invoice Company Details starts -->
            <div id="invoice-company-details" class="row">
              <div class="col-md-6 col-12">
                <div class="media">
                  <img
                    src="assets/img/logos/logo-color-big.png"
                    alt="company logo"
                    width="80"
                    height="80"
                  />
                  <div class="media-body ml-4">
                    <ul class="m-0 list-unstyled">
                      <li class="text-bold-800">Meet N Train LLC</li>
                      <li>Sharjah Media City,</li>
                      <li>Sharjah, UAE</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12 text-right">
                <h2 class="primary text-uppercase">Invoice Summary</h2>
                <ul class="px-0 list-unstyled">
                  <li>Amount Due</li>
                  <li class="font-medium-2 text-bold-700">
                    {{ organizerInvoicesSummary?.earnings }} AED
                  </li>
                </ul>
              </div>
            </div>
            <!-- Invoice Company Details ends -->
            <!-- Invoice Customer Details starts -->
            <div id="invoice-customer-details" class="row">
              <div class="col-12 text-left">
                <p class="text-muted mb-1">Bill To</p>
              </div>
              <div class="col-md-6 col-12">
                <ul class="m-0 list-unstyled">
                  <li class="text-bold-800">
                    {{
                      submissionForm?.fullName
                    }}
                  </li>
                  <li>
                    ,{{
                      submissionForm?.address
                    }}
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-12 text-right">
                <p>
                  <span class="text-muted">Invoice Date :</span>
                  {{ dueDate | date }}
                </p>
                <p><span class="text-muted">Terms :</span> Due on Receipt</p>
                <p class="m-0">
                  <span class="text-muted">Due Date :</span>
                  {{ dueDate | date }}
                </p>
              </div>
            </div>
            <!-- Invoice Customer Details ends -->
            <!-- Invoice Items Details starts -->
            <div id="invoice-items-details">
              <div class="row">
                <div class="table-responsive col-12" style="overflow-x: auto">
                  <table class="table mt-3">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            (change)="checkAllCheckBox($event)"
                            [checked]="isAllCheckBoxChecked()"
                          />
                        </th>
                        <th>ID</th>
                        <th
                        *ngIf="organizerInvoicesSummary?.invoices[0]?.packageTitle"
                        >Package Title</th>
                        <th
                          *ngIf="organizerInvoicesSummary?.invoices[0]?.eventTitle"
                        >Event Title</th>
                        <th>Total Amount</th>
                        <th>Cash Commission</th>
                        <th>Online Commission</th>
                        <th>Cash Amount</th>
                        <th>Online Amount</th>
                        <th>Earnings</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let invoice of organizerInvoicesSummary?.invoices
                        "
                      >
                        <td>
                          <input
                            id="selectBox"
                            type="checkbox"
                            [value]="invoice.id"
                            [checked]="invoice.checked"
                            (change)="onCheckboxChange($event)"
                          />
                        </td>
                        <th scope="row">{{ invoice?.id }}</th>
                        <td *ngIf="organizerInvoicesSummary?.invoices[0]?.packageTitle"
                        >{{ invoice?.packageTitle }}</td>
                        <td
                        *ngIf="organizerInvoicesSummary?.invoices[0]?.eventTitle"
                        >{{ invoice?.eventTitle }}</td>
                        <td>{{ invoice?.amount }} AED</td>
                        <td>{{ invoice?.commission }} %</td>
                        <td>{{ invoice?.cashCommission }} %</td>
                        <td>{{ invoice?.cashPaymentAmount }}</td>
                        <td>{{ invoice?.onlinePaymentAmount }}</td>
                        <td>{{ invoice?.earnings }} AED</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row mt-3 mt-md-0">
                <div class="col-md-6 col-12 text-left">
                  <p class="text-bold-700 mb-1 ml-1">Payment Method:</p>
                  <div class="row">
                    <div class="col-12">
                      <table class="table table-borderless table-sm">
                        <tbody>
                          <tr>
                            <td>Bank name:</td>
                            <td class="text-right">
                              {{
                                submissionForm
                                  ?.bankName
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Bank Address:</td>
                            <td class="text-right">
                              {{
                                submissionForm
                                  ?.bankAddress
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Account Number:</td>
                            <td class="text-right">
                              {{
                                submissionForm
                                  ?.bankAccountNumber
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Account Email:</td>
                            <td class="text-right">
                              {{
                                submissionForm
                                  ?.email
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Account Phone Number:</td>
                            <td class="text-right">
                              {{
                                submissionForm
                                  ?.phoneNumber
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Account Name:</td>
                            <td class="text-right">
                              {{
                                submissionForm
                                  ?.fullName
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>IBAN:</td>
                            <td class="text-right">
                              {{
                                submissionForm
                                  ?.iban
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>SWIFT code:</td>
                            <td class="text-right">
                              {{
                                submissionForm
                                  ?.swiftCode
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <p class="text-bold-700 mb-2 ml-4">Identity</p>
                  <div class="row">
                    <div
                      class="col-4"
                      *ngIf="
                        submissionForm
                          ?.nationalIdFront
                      "
                    >
                      <a
                        [href]="
                          'https://ik.imagekit.io/tnc8pizf9e/' +
                          submissionForm
                            ?.nationalIdFront
                        "
                        target="_blank"
                      >
                        <img
                          [src]="
                            'https://ik.imagekit.io/tnc8pizf9e/' +
                            submissionForm
                              ?.nationalIdFront +
                            '?tr=w-200,h-200'
                          "
                          alt="national id front"
                          title="national id front"
                        />
                      </a>
                    </div>

                    <div class="col-2"></div>
                    <div
                      class="col-4"
                      *ngIf="
                        submissionForm
                          ?.nationalIdBack
                      "
                    >
                      <a
                        [href]="
                          'https://ik.imagekit.io/tnc8pizf9e/' +
                          submissionForm
                            ?.nationalIdBack
                        "
                        target="_blank"
                      >
                        <img
                          [src]="
                            'https://ik.imagekit.io/tnc8pizf9e/' +
                            submissionForm
                              ?.nationalIdBack +
                            '?tr=w-200,h-200'
                          "
                          alt="national id back"
                          title="national id back"
                      /></a>
                    </div>
                  </div>

                  <div
                    style="margin-top: 20px"
                    *ngIf="
                      submissionForm?.passport
                    "
                  >
                    <a
                      [href]="
                        'https://ik.imagekit.io/tnc8pizf9e/' +
                        submissionForm?.passport
                      "
                      target="_blank"
                    >
                      <img
                        [src]="
                          'https://ik.imagekit.io/tnc8pizf9e/' +
                          submissionForm
                            ?.passport +
                          '?tr=w-200,h-200'
                        "
                        alt="passport"
                        title="passport"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Invoice Items Details ends -->
            <!-- Invoice Footer starts -->
            <div id="invoice-footer">
              <div class="row">
                <div class="col-12 text-center text-sm-right">
                  <button
                    type="button"
                    class="btn btn-primary btn-print mt-2 mt-md-1"
                    (click)="payout()"
                    [disabled]="!invoicesIds.length"
                  >
                    Payout
                  </button>
                </div>
              </div>
            </div>
            <!-- Invoice Footer ends -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Invoice template ends -->
