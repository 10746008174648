import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenService } from "app/shared/auth/token.service";
import { environment } from "environments/environment";
import { User } from "../models/users.model";

@Injectable({
  providedIn: "root",
})
export class AccountSettingsService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: this.tokenService.getToken(),
    };
    return new HttpHeaders(headersConfig);
  }

  getProfile() {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/admin`, {
      headers: this.setHeaders(),
    });
  }

  changeAdminPassword(body: User) {
    return this.httpClient.patch(
      `${environment.apiUrl}/dashboard/admin`,
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }

  updateAdminProfile(body: User) {
    return this.httpClient.put(`${environment.apiUrl}/dashboard/admin`, body, {
      headers: this.setHeaders(),
    });
  }

  upload(body: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/dashboard/files/upload`,
      body,
      {
        headers: {
          Accept: "application/json",
          authorization: this.tokenService.getToken(),
        },
      }
    );
  }

  updateAdminProfilePicture(body: { images: string }) {
    return this.httpClient.put(
      `${environment.apiUrl}/dashboard/files/upload`,
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }

  delete(path: string) {
    return this.httpClient.delete(`${environment.apiUrl}${path}`, {
      headers: this.setHeaders(),
    });
  }
}
