import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenService } from "app/shared/auth/token.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import FormSubmissionUpdate from "../models/form-submission-update.model";
import { FormSubmission } from "../../../models/form-submission.model";

@Injectable({
  providedIn: "root",
})
export class FormSubmissionService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: this.tokenService.getToken(),
    };
    return new HttpHeaders(headersConfig);
  }

  getFormSubmission(userId, organizerType): Observable<FormSubmission> {
    let url = organizerType === 'freelancer' ? `/dashboard/users/${userId}/submission-form` : `/dashboard/communities/${userId}/submission-form`;
    return this.httpClient.get<FormSubmission>(
      `${environment.apiUrl}${url}`,
      {
        headers: this.setHeaders(),
      }
    );
  }

  updateSubmissionForm(formId, body: FormSubmissionUpdate): Observable<any> {
    return this.httpClient.patch<FormSubmissionUpdate>(
      `${environment.apiUrl}/dashboard/submission-form/${formId}`,
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }
}
