import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InvoicePageComponent } from './invoices-history/invoice/invoice-page.component';
import { AccountSettingsComponent } from './users/account-settings/account-settings.component';
import { UsersEarningListComponent } from './invoices-history/user-earning/users-earning-list.component';
import { UsersViewComponent } from './users/users-view/users-view.component';
import { UsersEditComponent } from './users/users-edit/users-edit.component';
import { FormsSubmissionComponent } from './users/forms-submisson/froms-submission-list/forms-submission-list.component';
import { FormSubmissionComponent } from './users/forms-submisson/form-submission/form-submission.component';
import { CommunitiesComponent } from './communities/communitiesList/communities-list.component';
import { CommunityComponent } from './communities/community/community.component';
import { EventsComponent } from './events/eventsList/events-list.component';
import { EventComponent } from './events/event/event.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CommunitiesRevenueComponent } from './communities/communitiesRevenue/communities-revenue.component';
import { CharitiesComponent } from './charities/communitiesList/charities-list.component';
import { CharityComponent } from './charities/charity/charity.component';
import {TiersListClassComponent} from './tiers/tiers-list/tiers-list-class.component';
import {ItemPageComponent} from './invoices-history/items-invoice/item-page.component';
import {ItemInvoiceListComponent} from './invoices-history/items-invoice/item-invoice-list.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'invoice/:id',
        component: InvoicePageComponent,
        data: {
          title: 'Invoice Page',
        },
      },
      {
        path: 'item-invoice/:id',
        component: ItemPageComponent,
        data: {
          title: 'Invoice Page',
        },
      },
      {
        path: 'organizers/:id',
        component: FormSubmissionComponent,
        data: {
          title: 'Organizer Page',
        },
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent,
        data: {
          title: 'Account Settings Page',
        },
      },
      {
        path: 'communities',
        component: CommunitiesComponent,
        data: {
          title: 'Communities',
        },
      },
      {
        path: 'communities/:communityId',
        component: CommunityComponent,
        data: {
          title: 'Community',
        },
      },
      {
        path: 'charities',
        component: CharitiesComponent,
        data: {
          title: 'Charities',
        },
      },
      {
        path: 'charities/:charityId',
        component: CharityComponent,
        data: {
          title: 'Charity',
        },
      },
      {
        path: 'communities/revenue/list',
        component: CommunitiesRevenueComponent,
        data: {
          title: 'Communities Revenue',
        },
      },
      {
        path: 'events',
        component: EventsComponent,
        data: {
          title: 'Events',
        },
      },
      {
        path: 'events/:eventId',
        component: EventComponent,
        data: {
          title: 'Event Details',
        },
      },
      {
        path: 'users-earning',
        component: UsersEarningListComponent,
        data: {
          title: 'List',
        },
      },
      {
        path: 'packages',
        component: UsersEarningListComponent,
        data: {
          title: 'List',
        },
      },
      {
        path: 'item-invoice',
        component: ItemInvoiceListComponent,
        data: {
          title: 'List',
        },
      },
      {
        path: 'organizers',
        component: FormsSubmissionComponent,
        data: {
          title: 'Organizers',
        },
      },
      {
        path: 'users-view',
        component: UsersViewComponent,
        data: {
          title: 'View',
        },
      },
      {
        path: 'users-edit',
        component: UsersEditComponent,
        data: {
          title: 'Edit',
        },
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        data: {
          title: 'Notifications',
        },
      },
      {
        path: 'tiers',
        component: TiersListClassComponent,
        data: {
          title: 'Tiers',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FullPagesRoutingModule {}
