<div class="row">
  <div class="col-12">
    <div class="content-header">{{ community?.title }}</div>
  </div>
</div>
<!-- Community Page starts -->
<div class="row justify-content-center">
  <div class="col-md-9">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="tab-content">
            <div class="media">
              <img
                [src]="
                  'https://ik.imagekit.io/tnc8pizf9e/' +
                  community?.profile_picture +
                  '?tr=w-800,h-200'
                "
                alt="profile-img"
              />
            </div>

            <hr class="mt-1 mt-sm-2" />
            <div>
              <div class="row">
                <div class="col-12 form-group">
                  <label>Title</label>
                  <p>{{ community?.title }}</p>
                </div>
                <div class="col-12 form-group">
                  <label>Bio</label>
                  <p>{{ community?.bio }}</p>
                </div>
                <div class="col-12 form-group">
                  <label>State</label>
                  <p>{{ community?.state }}</p>
                </div>
                <div
                  class="col-12 d-flex flex-sm-row flex-column justify-content-end"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col justify-content-center">
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <h5 class="card-title">Revenue Data</h5>
        <form [formGroup]="dateRangeForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-6 form-group">
              <label for="fromDate">From Date</label>
              <input
                type="date"
                id="fromDate"
                formControlName="fromDate"
                class="form-control"
                required
                [min]="minDate"
              />
            </div>
            <div class="col-6 form-group">
              <label for="toDate">To Date</label>
              <input
                type="date"
                id="toDate"
                formControlName="toDate"
                class="form-control"
                required
                [min]="minDate"
              />
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="loading"
              >
                Get Revenue
              </button>
            </div>
          </div>
        </form>

        <!-- Display Revenue Data -->
        <div *ngIf="communityRevenue">
          <ul class="list-group mt-3">
            <li class="list-group-item">
              <strong>Cash Payment Amount:</strong>
              {{ communityRevenue.cashPaymentAmount }}
            </li>
            <li class="list-group-item">
              <strong>Online Payment Amount:</strong>
              {{ communityRevenue.onlinePaymentAmount }}
            </li>
            <li class="list-group-item">
              <strong>Cash Over Amount:</strong>
              {{ communityRevenue.cashOverAmount }}
            </li>
            <li class="list-group-item">
              <strong>Total Revenue:</strong>
              {{ totalRevenue }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


<ngx-spinner></ngx-spinner>
