import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { User } from "../models/users.model";

import { AccountSettingsService } from "./account-settings.service";

@Component({
  selector: "app-account-settings",
  templateUrl: "./account-settings.component.html",
  styleUrls: [
    "./account-settings.component.scss",
    "../../../../../assets/sass/libs/select.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AccountSettingsComponent implements OnInit {
  activeTab = "general";
  generalFormSubmitted = false;
  upload = false;
  changePasswordFormSubmitted = false;
  alertVisible = true;

  fileToUpload: File | null = null;

  generalForm: FormGroup;
  changePasswordForm: FormGroup;

  constructor(
    private accountSettingsService: AccountSettingsService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,

    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.generalForm = new FormGroup({
      firstName: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      profilePicture: new FormControl("", [Validators.required]),
    });

    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl("", [Validators.required]),
      newPassword: new FormControl("", [Validators.required]),
      confirmPassword: new FormControl("", [Validators.required]),
    });

    this.accountSettingsService.getProfile().subscribe((res: User) => {
      this.generalForm.patchValue(res);
      this.cdr.markForCheck();
    });
  }

  setActiveTab(tab) {
    this.activeTab = tab;
  }

  get gf() {
    return this.generalForm.controls;
  }

  get cpf() {
    return this.changePasswordForm.controls;
  }

  onGeneralFormSubmit() {
    this.generalFormSubmitted = true;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.accountSettingsService
      .updateAdminProfile(this.generalForm.value)
      .subscribe((res: User) => {
        this.spinner.hide();
        this.toastr.success("Update Success");
        this.cdr.markForCheck();
      }),
      (err) => {
        console.log(err);
        this.spinner.hide();
        err.message && this.toastr.error(err.message, "Update Faild");
        err.error.message.errors.map((error) => {
          this.toastr.error(error.message, "Update Faild");
        });
      };
  }

  onChangePasswordFormSubmit() {
    this.changePasswordFormSubmitted = true;

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.accountSettingsService
      .changeAdminPassword(this.changePasswordForm.value)
      .subscribe((res: User) => {
        this.spinner.hide();
        this.toastr.success("Update Success");
      }),
      (err) => {
        console.log(err);
        err.message && this.toastr.error(err.message, "Update Faild");
        err.error.message.errors.map((error) => {
          this.toastr.error(error.message, "Update Faild");
        });

        this.spinner.hide();
      };
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (!this.fileToUpload) return;
    this.uploadFile();
  }

  uploadFile() {
    const formData: FormData = new FormData();
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    formData.append("images", this.fileToUpload, this.fileToUpload.name);
    this.accountSettingsService.upload(formData).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.gf.profilePicture.setValue(data[0]);
        this.upload = true;
        this.generalForm.value.profilePicture = data[0];
        this.cdr.markForCheck();
      },
      (error) => {
        this.upload = false;
        this.spinner.hide();
        console.log(error);
      }
    );
  }
}
