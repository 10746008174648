import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-tags-notifications",
  templateUrl: "./tags-notifications.component.html",
  styleUrls: ["./tags-notifications.component.scss"],
})
export class TagsNotificationsComponent implements OnInit {
  infoFormSubmitted: boolean = false;
  selectedTagAudience: string[] = [];

  tagAudience = [
    { value: 0, name: "Communities" },
    { value: 1, name: "Events" },
    { value: 1, name: "Users" },
  ];

  selectedTags = ["Sports", "Football"];
  tags = [
    { value: "Sports", name: "Sports" },
    { value: "Football", name: "Football" },
    { value: "Swim", name: "Swim" },
    { value: "Badminton", name: "Badminton" },
    { value: "Fly", name: "Fly" },
    { value: "Basketball", name: "Basketball" },
  ];

  tagNotificationsForm = new FormGroup({
    title: new FormControl("", [Validators.required]),
    message: new FormControl(""),
    audience: new FormControl(this.selectedTagAudience, [Validators.required]),
    tags: new FormControl(this.selectedTags),
    entityType: new FormControl("Tag"),
  });

  constructor() {}

  get cnf() {
    return this.tagNotificationsForm.controls;
  }
  ngOnInit(): void {}

  onTagNotificationsFormSubmit() {
    this.infoFormSubmitted = true;
    if (this.cnf.invalid) {
      return;
    }
    console.log(this.tagNotificationsForm.value);
  }
  public onSelectAll() {
    this.selectedTags = this.tags.map((item) => item.name);
    console.log({ selectedLanguages: this.selectedTags });
    this.tagNotificationsForm.get("tags").patchValue(this.selectedTags);
  }

  public onClearAll() {
    this.selectedTags = [];
    console.log({ selectedLanguages: this.selectedTags });
    this.tagNotificationsForm.get("tags").patchValue([]);
  }
  onSelectedTagsChange(tag) {
    console.log({ tag });
    this.selectedTags = tag.map((item) => item.name);
    this.tagNotificationsForm.get("tags").patchValue(this.selectedTags);
  }
  onSelectedTagAudienceChange(tag) {
    console.log({ tag });
    this.selectedTagAudience = tag.name;

    this.tagNotificationsForm
      .get("audience")
      .patchValue(this.selectedTagAudience);
  }
  onResetForm() {
    this.selectedTags = [];
    this.selectedTagAudience = [];
  }
}
