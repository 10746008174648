import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from 'app/shared/auth/token.service';
import ResponseWrapper from 'app/shared/models/response.models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import InvoicesPayoutRequest from './models/invoices-payout-request.model';
import Invoice from './models/invoices.model';
import OrganizerInvoicesSummary from './models/organizer-invoices-summary.model';
import { OrganizerEarningInvoices } from './models/organizer.earning.invoices.model';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: this.tokenService.getToken(),
    };
    return new HttpHeaders(headersConfig);
  }

  getOrganizerInvoicesSummary(id: number, organizerType: string): Observable<OrganizerInvoicesSummary> {
    const url = organizerType === 'freelancer' ? `dashboard/invoices/organizer/${id}` :
      organizerType === 'organization' ? `dashboard/invoices/organization/${id}/events` :
      `dashboard/invoices/organization/${id}/packages`;
    return this.httpClient.get<OrganizerInvoicesSummary>(
      `${environment.apiUrl}/${url}`,
      {
        headers: this.setHeaders(),
      }
    );
  }

  getItemInvoiceSummary(userId: number): Observable<OrganizerInvoicesSummary> {
    return this.httpClient.get<OrganizerInvoicesSummary>(
      `${environment.apiUrl}/dashboard/invoices/organizer/${userId}/items`,
      {
        headers: this.setHeaders(),
      }
    );
  }

  getItemsInvoices(options?: { page: number; }): Observable<ResponseWrapper<OrganizerEarningInvoices>> {
    return this.httpClient.get<ResponseWrapper<OrganizerEarningInvoices>>(
      `${environment.apiUrl}/dashboard/invoices/organizers/items/earning?page=${
        options?.page || 1
      }`,
      {
        headers: this.setHeaders(),
      }
    );
  }

  payout(body: InvoicesPayoutRequest): Observable<any> {
    return this.httpClient.patch<InvoicesPayoutRequest>(
      `${environment.apiUrl}/dashboard/invoices`,
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }
  getInvoices(organizerType?: string, options?: { page: number; }): Observable<ResponseWrapper<OrganizerEarningInvoices>> {
    const url = organizerType === 'freelancer'  ? `dashboard/invoices/organizers/earning` :
      organizerType === 'organization' ? `dashboard/invoices/organizations/events/earning` :
      `dashboard/invoices/organizations/packages/earning`;
    return this.httpClient.get<ResponseWrapper<OrganizerEarningInvoices>>(
      `${environment.apiUrl}/${url}?page=${
        options?.page || 1
      }`,
      {
        headers: this.setHeaders(),
      }
    );
  }

  getOrganizerInvoicesHistory(organizerId: number, organizerType?: string, options?: { page: number; }): Observable<ResponseWrapper<Invoice>> {
    console.log({organizerId, organizerType})
    const url =  organizerType === 'freelancer' ? `dashboard/invoices/user/${organizerId}` :
              `dashboard/invoices/organization/${organizerId}`
    return this.httpClient.get<ResponseWrapper<Invoice>>(
      `${environment.apiUrl}/${url}?page=${
        options?.page || 1
      }`,
      {
        headers: this.setHeaders(),
      }
    );
  }
  post(path: string, body: Object = {}) {
    return this.httpClient.post(`${environment.apiUrl}${path}`, body, {
      headers: this.setHeaders(),
    });
  }
  put(path: string, body: Object = {}) {
    return this.httpClient.put(`${environment.apiUrl}${path}`, body, {
      headers: this.setHeaders(),
    });
  }
  delete(path: string) {
    return this.httpClient.delete(`${environment.apiUrl}${path}`, {
      headers: this.setHeaders(),
    });
  }
}
