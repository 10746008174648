import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenService } from "app/shared/auth/token.service";
import ResponseWrapper from "app/shared/models/response.models";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { FormSubmission } from "../../../models/form-submission.model";

@Injectable({
  providedIn: "root",
})
export class FormsSubmissionService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: this.tokenService.getToken(),
    };
    return new HttpHeaders(headersConfig);
  }

  getAllFormsSubmission(organizerType?: string, options?: { page: number; }): Observable<ResponseWrapper<FormSubmission>> {
    const url = !organizerType || organizerType === 'freelancer'  ? `dashboard/users/submitted-forms` : `dashboard/communities/submitted-forms`;
    return this.httpClient.get<ResponseWrapper<FormSubmission>>(
      `${environment.apiUrl}/${url}?page=${
        options?.page || 1
      }`,
      {
        headers: this.setHeaders(),
      }
    );
  }
  post(path: string, body: Object = {}) {
    return this.httpClient.post(`${environment.apiUrl}${path}`, body, {
      headers: this.setHeaders(),
    });
  }
  put(path: string, body: Object = {}) {
    return this.httpClient.put(`${environment.apiUrl}${path}`, body, {
      headers: this.setHeaders(),
    });
  }
  delete(path: string) {
    return this.httpClient.delete(`${environment.apiUrl}${path}`, {
      headers: this.setHeaders(),
    });
  }
}
