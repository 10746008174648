<div class="row">
  <app-modal
    [showModal]="showModal"
    [modalTitle]="modalTitle"
    (closeModal)="closeModal()">

    <form [formGroup]="formData">
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" id="name" formControlName="name">
      </div>
      <div class="form-group">
        <label for="from">From</label>
        <input type="number" class="form-control" id="from" formControlName="from">
      </div>
      <div class="form-group">
        <label for="to">To</label>
        <input type="number" class="form-control" id="to" formControlName="to">
      </div>
      <div class="form-group">
        <label for="commissionPercentage">Commission Percentage</label>
        <input type="number" class="form-control" id="commissionPercentage" formControlName="commissionPercentage">
      </div>
      <button (click)="handleSubmit()" type="submit" class="btn btn-primary btn-print mt-2 mt-md-1">Submit</button>
    </form>
  </app-modal>
  <div class="col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body" style="overflow-x: auto">
          <div class="headerRow">
            <div class="form-group">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    [class.active]="organizerType === 'freelancer'"
                    id="freelancer-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="freelancer"
                    aria-selected="true"
                    (click)="filterOrganizationType('freelancer')"
                  >
                    Freelancer
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    [class.active]="organizerType === 'organization'"
                    id="organization-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="organization"
                    aria-selected="false"
                    (click)="filterOrganizationType('organization')"
                  >
                    Organization
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <button
                class="btn btn-primary btn-print mt-2 mt-md-1"
                (click)="openModal('add')"
              >
                Add
              </button>
            </div>
          </div>

          <table class="table">
            <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col">From</th>
              <th scope="col">To</th>
              <th scope="col">Commission Percentage</th>
              <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let tiersList of tiersListData">
              <td>{{ tiersList.id }}</td>
              <td>
                <a
                  [routerLink]="[
                      '/pages/organizers',
                      tiersList?.id
                    ]"
                >
                  {{ tiersList.name }}
                </a>
              </td>
              <td>{{ tiersList.from }}</td>
              <td>{{ tiersList.to }}</td>
              <td>{{ tiersList.commissionPercentage }}</td>
              <td>{{ organizerType }}</td>
              <td>
                <button style="background-color: transparent; border: none">
                  <a  (click)="openModal('edit', tiersList)">
                    <i class="ft-edit text-primary cursor-pointer"></i>
                  </a>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
<!--          <div>-->
<!--            <ngb-pagination-->
<!--              [collectionSize]="tiersListMeta?.total"-->
<!--              (pageChange)="pageChange($event)"-->
<!--              [(page)]="page"-->
<!--              pageSize="10"-->
<!--              [maxSize]="5"-->
<!--              [rotate]="true"-->
<!--              [ellipses]="true"-->
<!--              [boundaryLinks]="false"-->
<!--            ></ngb-pagination>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
