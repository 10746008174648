import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Charity } from "../models/charity.model";
import { CharityService } from "../service/charities.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from "app/shared/services/excel.service";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-charity-page",
  templateUrl: "./charity.component.html",
  styleUrls: ["./charity.component.scss"],
})
export class CharityComponent implements OnInit {
  charityId: number;
  charity: Charity;
  charitySubInvoices: [];
  charityExportInvoices: [];
  invoiceId: number;
  loading: boolean = false;
  page = 1;
  pageSize: number;

  constructor(
    private route: ActivatedRoute,
    private charityService: CharityService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.charityId = +params["charityId"];
    });
    this.spinner.show();

    this.charityService
      .getCharityInvoices(this.charityId)
      .pipe(
        switchMap((response: any) => {
          this.charity = response.data;
          this.invoiceId = this.charity[0].id;
          this.cdr.markForCheck();
          return this.charityService.getCharitySubInvoices(this.invoiceId);
        })
      )
      .subscribe((response: any) => {
        this.charitySubInvoices = response.data;
        this.pageSize = response?.meta?.total;
        this.charityExportInvoices = response.data.map((invoice: any) => {
          return {
            id: invoice.id,
            firstName: invoice.user.first_name,
            lastName: invoice.user.last_name,
            event: invoice.event?.title || "",
            amount: invoice.amount,
          };
        });
        this.spinner.hide();
      });
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(
      this.charityExportInvoices,
      "charity_invoices"
    );
  }

  pageChange(pageNumber = 1) {
    const options: { page: number } = {
      page: pageNumber,
    };
    this.spinner.show();

    this.charityService
      .getCharitySubInvoices(this.invoiceId, options)
      .subscribe((response: any) => {
        this.pageSize = response?.meta?.total;
        this.charitySubInvoices = response.data;
        this.cdr.markForCheck();
        this.spinner.hide();
      });
  }
}
