import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import ResponseWrapper from "app/shared/models/response.models";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Charity } from "../models/charity.model";
import { CharityService } from "../service/charities.service";

@Component({
  selector: "app-charities-list",
  templateUrl: "./charities-list.component.html",
  styleUrls: ["./charities-list.component.scss"],
})
export class CharitiesComponent implements OnInit {
  page = 1;
  pageSize: number;
  searchValue: string;
  charities: Charity[];

  constructor(
    private charityService: CharityService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,

    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.charityService.getCharities().subscribe((response: any) => {
      this.charities = response;
      // this.pageSize = response?.meta?.total;
      this.cdr.markForCheck();
    });
  }
  // pageChange(pageNumber = 1) {
  //   const options: { page?: number; search?: string } = {
  //     page: pageNumber,
  //   };

  //   if (this.searchValue) {
  //     options.search = this.searchValue;
  //   }
  //   this.spinner.show();

  //   this.charityService
  //     .getCharities(options)
  //     .subscribe((response: ResponseWrapper<Charity>) => {
  //       this.charities = response.data;
  //       this.pageSize = response?.meta?.total;
  //       this.cdr.markForCheck();
  //       this.spinner.hide();
  //     });
  // }
}
