<form [formGroup]="notificationsForm" (ngSubmit)="onNotificationsFormSubmit()">
  <div class="row">
    <div class="col-12 form-group">
      <label for="title">Title</label>
      <input
        formControlName="title"
        type="text"
        class="form-control"
        placeholder="Notification Heading"
      />
    </div>
    <div class="col-12 form-group">
      <label for="message">Message</label>
      <textarea
        formControlName="message"
        class="form-control"
        placeholder="Notification Message"
        rows="3"
      ></textarea>
    </div>
    <div class="col-12 form-group">
      <label for="audience">Entity Type</label>
      <div class="controls">
        <ng-select
          [items]="entityTypes"
          bindLabel="name"
          placeholder="Select Entity Type For your targeted action"
          [(ngModel)]="selectedEntityType"
          [ngModelOptions]="{ standalone: true }"
          (change)="onSelectedEntityTypeChange($event)"
        >
        </ng-select>
      </div>
    </div>
    <div class="col-12 form-group">
      <label for="entity">Entity</label>
      <div class="controls">
        <ng-select
          [disabled]="selectedEntityType ? false : true"
          placeholder="Select Entity"
          [(ngModel)]="selectedEntityTitle"
          [ngModelOptions]="{ standalone: true }"
          (open)="onEntityTypeDropDownOpen()"
        >
        </ng-select>
      </div>
    </div>

    <!-- Entity Type  -->
    <div class="col-12 form-group">
      <label for="audience">Audience Entity Type</label>
      <div class="controls">
        <ng-select
          [items]="entityTypes"
          bindLabel="name"
          placeholder="Select Audience Entity Type"
          [(ngModel)]="selectedAudienceEntityType"
          [ngModelOptions]="{ standalone: true }"
          (change)="onSelectedAudienceEntityTypeChange($event)"
        >
        </ng-select>
      </div>
    </div>

    <!-- Audience Entities -->
    <div class="col-12 form-group">
      <label for="entity">Audience Entity</label>
      <div class="controls">
        <ng-select
          placeholder="Select Audience Entity"
          bindLabel="title"
          [disabled]="selectedAudienceEntityType ? false : true"
          [items]="selectedAudienceEntities"
          [multiple]="true"
          [(ngModel)]="selectedAudienceEntityTitles"
          [ngModelOptions]="{ standalone: true }"
          (open)="onAudienceEntityDropDownOpen()"
          (clear)="onClearAudienceEntities()"
          ><ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice: 0:2">
              <span
                style="cursor: pointer"
                (click)="clear(item)"
                class="ng-value-label"
              >
                {{ item.title }} ×
              </span>
            </div>
            <div class="ng-value" *ngIf="items.length > 2">
              <span class="ng-value-label">{{ items.length - 2 }} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <!-- Audience Selection -->
    <div class="col-12 form-group">
      <label for="audience">Audience</label>
      <div class="controls">
        <ng-select
          [items]="audience"
          bindLabel="name"
          placeholder="Select Entity Audience"
          [(ngModel)]="selectedAudience"
          [ngModelOptions]="{ standalone: true }"
          (change)="onSelectedAudienceChange($event)"
        >
        </ng-select>
      </div>
    </div>
    <!-- <div class="col-12 form-group">
      <label for="languages">Communities</label>
      <ng-select
        [items]="communities"
        bindLabel="name"
        bindValue="name"
        placeholder="Select Communities"
        [multiple]="true"
        [(ngModel)]="selectedCommunities"
        [ngModelOptions]="{ standalone: true }"
        (change)="onSelectedCommunitiesChange($event)"
      >
        <ng-option
          *ngFor="let community of selectedCommunities"
          [value]="community.value"
          >{{ community.name }} </ng-option
        ><ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice: 0:2">
            <span
              style="cursor: pointer"
              (click)="clear(item)"
              class="ng-value-label"
            >
              {{ item.name }} ×
            </span>
          </div>
          <div class="ng-value" *ngIf="items.length > 2">
            <span class="ng-value-label">{{ items.length - 2 }} more...</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp>
          <div>
            <button type="button" class="btn btn-link" (click)="onSelectAll()">
              Select All
            </button>
            <button type="button" class="btn btn-link" (click)="onClearAll()">
              Clear All
            </button>
          </div>
        </ng-template>
      </ng-select>
    </div> -->
    <div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
      <button type="submit" class="btn btn-primary mr-sm-2 mb-1">Send</button>
      <button
        (click)="onResetForm()"
        type="reset"
        class="btn btn-secondary mb-1"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
