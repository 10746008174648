import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Community } from "../models/communities.model";
import { CommunityService } from "../service/community.service";
import { DatePipe } from "@angular/common";
import { maxThreeMonthRangeValidator } from "./va";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-community-page",
  templateUrl: "./community.component.html",
  styleUrls: ["./community.component.scss"],
})
export class CommunityComponent implements OnInit {
  communityId: number;
  community: Community;
  communityRevenue: any;
  totalRevenue: number;
  dateRangeForm: FormGroup;
  minDate: string;
  loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private communityService: CommunityService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {
    const today = new Date();
    const startDate = new Date(2021, 0, 1);
    this.minDate = this.datePipe.transform(startDate, "yyyy-MM-dd");

    this.dateRangeForm = new FormGroup(
      {
        fromDate: new FormControl(startDate, Validators.required),
        toDate: new FormControl(today, Validators.required),
      }
      // { validators: maxThreeMonthRangeValidator() }
    );
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.communityId = +params["communityId"];
    });

    console.log({ communityId: this.communityId });

    this.communityService
      .getCommunity(this.communityId)
      .subscribe((response: Community) => {
        this.community = response;
        this.cdr.markForCheck();
      });
  }

  onSubmit() {
    if (this.dateRangeForm.hasError("maxThreeMonthRange")) {
      this.toastr.error(
        "Date range should not exceed 3 months.",
        "Form Validation Error"
      );
      return;
    } else if (this.dateRangeForm.invalid) {
      this.toastr.error("Please select valid dates.", "Form Validation Error");
      return;
    }

    this.loading = true;
    this.spinner.show();

    const fromDate = this.dateRangeForm.get("fromDate").value;
    const toDate = this.dateRangeForm.get("toDate").value;

    const formattedFromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd");
    const formattedToDate = this.datePipe.transform(toDate, "yyyy-MM-dd");

    // Call the service method with the selected date range
    this.communityService
      .getCommunityRevenue(this.communityId, formattedFromDate, formattedToDate)
      .subscribe(
        (revenueData: any) => {
          this.loading = false;
          this.spinner.hide();
          this.communityRevenue = revenueData;
          this.cdr.markForCheck();
          this.totalRevenue =
            +revenueData.cashPaymentAmount +
            +revenueData.onlinePaymentAmount +
            +revenueData.cashOverAmount;
          this.dateRangeForm.reset();
        },
        (error) => {
          this.loading = false;
          this.spinner.hide();
          console.error(error);
        }
      );
  }
}
