import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';

import { FullPagesRoutingModule } from './full-pages-routing.module';
import { ChartistModule } from 'ng-chartist';
import { AgmCoreModule } from '@agm/core';
import { NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { PipeModule } from 'app/shared/pipes/pipe.module';

import { InvoicePageComponent } from './invoices-history/invoice/invoice-page.component';
import { AccountSettingsComponent } from './users/account-settings/account-settings.component';
import { UsersEarningListComponent } from './invoices-history/user-earning/users-earning-list.component';
import { FormsSubmissionComponent } from './users/forms-submisson/froms-submission-list/forms-submission-list.component';
import { UsersViewComponent } from './users/users-view/users-view.component';
import { UsersEditComponent } from './users/users-edit/users-edit.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormSubmissionComponent } from './users/forms-submisson/form-submission/form-submission.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommunitiesComponent } from './communities/communitiesList/communities-list.component';
import { CommunityComponent } from './communities/community/community.component';
import { EventsComponent } from './events/eventsList/events-list.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotifyComponent } from './notifications/notify/notify.component';
import { TagsNotificationsComponent } from './notifications/tags-notifications/tags-notifications.component';
import { NotificationsEntityModalContentComponent } from './notifications/notifications-modal-content/notifications-entity-modal-content.component';
import { CommunitiesRevenueComponent } from './communities/communitiesRevenue/communities-revenue.component';
import { CharitiesComponent } from './charities/communitiesList/charities-list.component';
import { CharityComponent } from './charities/charity/charity.component';
import {TiersListClassComponent} from './tiers/tiers-list/tiers-list-class.component';
import {ModalComponent} from '../../shared/Modal/Modal.Component';
import {SharedModule} from '../../shared/shared.module';
import {ItemPageComponent} from './invoices-history/items-invoice/item-page.component';
import {ItemInvoiceListComponent} from './invoices-history/items-invoice/item-invoice-list.component';
@NgModule({
  imports: [
    CommonModule,
    FullPagesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartistModule,
    AgmCoreModule,
    NgSelectModule,
    NgbModule,
    SwiperModule,
    PipeModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    UiSwitchModule,
    NgSelectModule,
  ],
  declarations: [
    InvoicePageComponent,
    ItemPageComponent,
    ItemInvoiceListComponent,
    AccountSettingsComponent,
    UsersEarningListComponent,
    FormsSubmissionComponent,
    UsersViewComponent,
    UsersEditComponent,
    FormSubmissionComponent,
    CommunitiesComponent,
    CommunityComponent,
    CharitiesComponent,
    CharityComponent,
    CommunitiesRevenueComponent,
    EventsComponent,
    NotificationsComponent,
    NotifyComponent,
    TagsNotificationsComponent,
    NotificationsEntityModalContentComponent,
    TiersListClassComponent,
    ModalComponent
  ],
  providers: [DatePipe],
})
export class FullPagesModule {}
