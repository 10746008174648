<!-- Filter Datatables -->
<!-- <section id="ngx-datatable-filter"> -->
<div class="card">
  <div class="card-content">
    <div class="card-body" style="overflow-x: auto">
      <div class="col-md-12 input-group mb-3 mt-2">
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          aria-label="Search..."
          aria-describedby="basic-addon2"
          (change)="search($event)"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="input-group-text btn btn-primary"
            id="basic-addon2"
          >
            <i class="ft-search mr-1 align-middle"></i>
            <span class="align-middle">Search</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body" style="overflow-x: auto">

          <div class="row">
            <div class="form-group">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    [class.active]="organizerType === 'freelancer'"
                    id="freelancer-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="freelancer"
                    aria-selected="true"
                    (click)="filterOrganizationType('freelancer')"
                  >
                    Freelancer
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    [class.active]="organizerType === 'organization'"
                    id="organization-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="organization"
                    aria-selected="false"
                    (click)="filterOrganizationType('organization')"
                  >
                    Organization
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">title</th>
                <th scope="col">State</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let community of communities">
                <td>{{ community?.id }}</td>
                <td>
                  <a [routerLink]="['/pages/communities', community?.id]">
                    {{ community?.title }}
                  </a>
                </td>
                <td>
                  <select
                    *ngIf="organizerType === 'freelancer'"
                    [style.color]="
                      community.state === 'verified' ? 'green' : 'red'
                    "
                    class="form-select"
                    aria-label="Default select example"
                    (change)="update(community.id, $event.target.value)"
                    [value]="community.state"
                  >
                    <option selected disabled>
                      {{ community?.state }}
                    </option>
                    <option class="text-danger" value="rejected">
                      Rejected
                    </option>
                    <option class="text-success" value="verified">
                      Verified
                    </option>
                    <option class="text-warning" value="pending">
                      Pending
                    </option>
                    <option class="text-secondary" value="blocked">
                      Blocked
                    </option>
                  </select>
                  <span
                    *ngIf="organizerType === 'organization'"
                    [style.color]="
                      community.state === 'verified' ? 'green' : 'red'
                    "
                  >
                    {{ community?.state }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <ngb-pagination
              [collectionSize]="pageSize"
              (pageChange)="pageChange($event)"
              [(page)]="page"
              pageSize="10"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="true"
              [boundaryLinks]="false"
            ></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </section> -->

<ngx-spinner></ngx-spinner>
