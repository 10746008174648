<div class="modal-header">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="
      activeModal.dismiss(
        selectType === 'multi' ? selectedEntities : selectedEntity
      )
    "
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="col-md-12 input-group mb-3 mt-2">
  <input
    type="text"
    class="form-control"
    placeholder="Search..."
    aria-label="Search..."
    aria-describedby="basic-addon2"
    (change)="search($event)"
    [(ngModel)]="modelWiki"
  />
  <div class="input-group-append">
    <button
      type="button"
      class="input-group-text btn btn-primary"
      id="basic-addon2"
    >
      <i class="ft-search mr-1 align-middle"></i>
      <span class="align-middle">Search</span>
    </button>
  </div>
</div>

<!--Wikipedia search Ends-->
<!-- Modal Body -->
<div class="modal-body">
  <button *ngIf="selectType == 'multi'" type="submit" class="btn btn-primary">
    <i class="ft-info mr-1 align-middle"></i>
    <span class="align-middle">Notify All</span>
  </button>
  <!-- Filter Datatables -->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body" style="overflow-x: auto">
            <table class="table">
              <thead>
                <tr>
                  <th *ngIf="selectType == 'multi'">
                    <input
                      type="checkbox"
                      (change)="checkAllCheckBox($event)"
                      [checked]="isAllCheckBoxChecked()"
                    />
                  </th>
                  <th scope="col">ID</th>
                  <th scope="col">title</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let entity of entitiesData">
                  <td *ngIf="selectType == 'multi'">
                    <input
                      id="selectBox"
                      type="checkbox"
                      [value]="entity.id"
                      [checked]="entity.checked"
                      (change)="onCheckboxChange($event)"
                    />
                  </td>
                  <td>{{ entity?.id }}</td>
                  <td
                    style="cursor: pointer"
                    (click)="onEntitySelected(entity)"
                  >
                    {{ entity?.title }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div>
              <ngb-pagination
                [collectionSize]="pageSize"
                (pageChange)="pageChange($event)"
                [(page)]="page"
                pageSize="10"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="true"
                [boundaryLinks]="false"
              ></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/ Filter Datatables -->
</div>

<!-- Modal Footer -->
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="
      activeModal.close(
        selectType === 'multi' ? selectedEntities : selectedEntity
      )
    "
  >
    {{ selectType === "multi" ? "Apply" : "Close" }}
  </button>
</div>
