<!-- Filter Datatables -->
<!-- <section id="ngx-datatable-filter"> -->
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body" style="overflow-x: auto">
          <ul *ngIf="organizerType !== 'packages'" class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link"
                [class.active]="organizerType === 'freelancer'"
                id="freelancer-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="freelancer"
                aria-selected="true"
                (click)="filterOrganizationType('freelancer')"
              >
                Freelancer
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [class.active]="organizerType === 'organization'"
                id="organization-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="organization"
                aria-selected="false"
                (click)="filterOrganizationType('organization')"
              >
                Organization
              </a>
            </li>
            </ul>
          <table *ngIf="organizerType === 'freelancer'" class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Username</th>
                <th scope="col">Earnings</th>
                <th scope="col">Total Invoices</th>
                <th scope="col">Payout</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let organizerEarningInvoice of organizerEarningData">
                <td>{{ organizerEarningInvoice.id }}</td>
                <td>
                  {{ organizerEarningInvoice.first_name }}
                  {{ organizerEarningInvoice.last_name }}
                </td>
                <td>{{ organizerEarningInvoice.earnings }}</td>
                <td>{{ organizerEarningInvoice.totalInvoices }}</td>
                <td>
                  <button style="background-color: transparent; border: none">
                    <a
                      [routerLink]="[
                        '/pages/invoice',
                        organizerEarningInvoice.id,
                         { organizerType: organizerType }
                      ]"
                    >
                      <i class="ft-edit text-primary cursor-pointer"></i>
                    </a>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <table *ngIf="organizerType !== 'freelancer'" class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Organization Name</th>
                <th scope="col">Owner</th>
                <th scope="col">Earnings</th>
                <th scope="col">Total Invoices</th>
                <th scope="col">Payout</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let organizationEarningInvoice of organizationEarningData">
                <td>{{ organizationEarningInvoice.organization.id }}</td>
                <td>{{ organizationEarningInvoice.organization.title }}</td>
                <td>
                  {{ organizationEarningInvoice.organizationOwner.firstName }}
                  {{ organizationEarningInvoice.organizationOwner.LastName }}
                </td>
                <td>{{ organizationEarningInvoice.earnings }}</td>
                <td>{{ organizationEarningInvoice.totalInvoices }}</td>
                <td>
                  <button style="background-color: transparent; border: none">
                    <a
                      [routerLink]="[
                        '/pages/invoice',
                        organizationEarningInvoice.organization.id,
                        { organizerType: organizerType }
                      ]"
                    >
                      <i class="ft-edit text-primary cursor-pointer"></i>
                    </a>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <ngb-pagination
              [collectionSize]="pageSize"
              (pageChange)="pageChange($event)"
              [(page)]="page"
              pageSize="10"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="true"
              [boundaryLinks]="false"
            ></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </section> -->
<!--/ Filter Datatables -->
