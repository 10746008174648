<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body" style="overflow-x: auto">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">title</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let charity of charities">
                <td>{{ charity?.id }}</td>
                <td>
                  <a [routerLink]="['/pages/charities', charity?.id]">
                    {{ charity?.title }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <div>
            <ngb-pagination
              [collectionSize]="pageSize"
              (pageChange)="pageChange($event)"
              [(page)]="page"
              pageSize="10"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="true"
              [boundaryLinks]="false"
            ></ngb-pagination>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </section> -->

<ngx-spinner></ngx-spinner>
