import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import ResponseWrapper from 'app/shared/models/response.models';
import { OrganizerEarningInvoices} from '../models/organizer.earning.invoices.model';
import { InvoiceService } from '../invoice.service';
import {ItemInvoice} from '../models/invoices.model';

@Component({
  selector: 'app-users-earning-list',
  templateUrl: './item-invoice-list.component.html',
  styleUrls: ['./item-invoice-list.component.scss'],
})
export class ItemInvoiceListComponent implements OnInit {
  page = 1;
  pageSize: number;
  organizerEarningData: ItemInvoice[];
  path: string;


  constructor(
    private invoiceService: InvoiceService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
  }
  ngOnInit(): void {
    this.invoiceService
      .getItemsInvoices()
      .subscribe((response: ResponseWrapper<OrganizerEarningInvoices>) => {
        this.organizerEarningData = response.data;
        this.pageSize = response?.meta?.total;
        this.cdr.markForCheck();
      });
  }
  pageChange(pageNumber = 1) {
    const options = {
      page: pageNumber,
    };
    this.invoiceService
      .getItemsInvoices(options)
      .subscribe((response: ResponseWrapper<OrganizerEarningInvoices>) => {
        this.organizerEarningData = response.data;
        this.pageSize = response?.meta?.total;
        this.cdr.markForCheck();
      });
  }

  filterOrganizationType(selectedValue: string) {
    this.pageChange();
  }
}
