<div class="mt-4" *ngIf="charityExportInvoices?.length > 0">
  <h3 class="">Exporting an excel file of type .xslx !!!</h3>
  <p>Export as XLSX by clicking the below button</p>
  <button (click)="exportAsXLSX()">
    <i class="fa fa-file-excel-o" style="font-size: 28px; color: blue"></i>
  </button>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body" style="overflow-x: auto">
          <table class="table">
            <tbody>
              <ng-container *ngFor="let charity of charity">
                <!-- Main Charity Information Row -->
                <tr>
                  <th>Title</th>
                  <th>Total Amount</th>
                  <th>Net Earning</th>
                  <th>MNT Commission</th>
                </tr>
                <tr>
                  <td>{{ charity?.title }}</td>
                  <td>{{ charity?.amount }}</td>
                  <td>{{ charity?.netEarning }}</td>
                  <td>
                    {{ (charity?.amount || 0) - (charity?.netEarning || 0) }}
                  </td>
                </tr>
                <!-- SubInvoices Row -->
                <th>Id</th>
                <th>User</th>
                <th>Event</th>
                <th>Amount</th>
                <th>Status</th>

                <tr *ngFor="let invoice of charitySubInvoices">
                  <td>{{ invoice.id }}</td>
                  <td>
                    {{ invoice.user.first_name }} {{ invoice.user.last_name }}
                  </td>
                  <td>{{ invoice.event?.title || "" }}</td>
                  <td>{{ invoice.amount }}</td>
                  <td
                    [style.color]="
                      invoice.status === 'COMPLETED' ? 'green' : 'red'
                    "
                  >
                    {{ invoice.status }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <div>
            <ngb-pagination
              [collectionSize]="pageSize"
              (pageChange)="pageChange($event)"
              [(page)]="page"
              pageSize="10"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="true"
              [boundaryLinks]="false"
            ></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
