<form
  [formGroup]="tagNotificationsForm"
  (ngSubmit)="onTagNotificationsFormSubmit()"
>
  <div class="row">
    <div class="col-12 form-group">
      <label for="title">Title</label>
      <input
        formControlName="title"
        type="text"
        class="form-control"
        placeholder="Notification Heading"
      />
    </div>
    <div class="col-12 form-group">
      <label for="message">Message</label>
      <textarea
        formControlName="message"
        class="form-control"
        placeholder="Notification Message"
        rows="3"
      ></textarea>
    </div>
    <div class="col-12 form-group">
      <label for="audience">Audience</label>
      <div class="controls">
        <ng-select
          [items]="tagAudience"
          bindLabel="name"
          placeholder="Select Tag Audience"
          [(ngModel)]="selectedTagAudience"
          [ngModelOptions]="{ standalone: true }"
          (change)="onSelectedTagAudienceChange($event)"
        >
        </ng-select>
      </div>
    </div>
    <div class="col-12 form-group">
      <label for="languages">Tags</label>
      <ng-select
        [items]="tags"
        bindLabel="name"
        bindValue="name"
        placeholder="Select Tags"
        [multiple]="true"
        [(ngModel)]="selectedTags"
        [ngModelOptions]="{ standalone: true }"
        (change)="onSelectedTagsChange($event)"
      >
        <ng-option *ngFor="let tag of selectedTags" [value]="tag.value"
          >{{ tag.name }} </ng-option
        ><ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice: 0:2">
            <span
              style="cursor: pointer"
              (click)="clear(item)"
              class="ng-value-label"
            >
              {{ item.name }} ×
            </span>
          </div>
          <div class="ng-value" *ngIf="items.length > 2">
            <span class="ng-value-label">{{ items.length - 2 }} more...</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp>
          <div>
            <button type="button" class="btn btn-link" (click)="onSelectAll()">
              Select All
            </button>
            <button type="button" class="btn btn-link" (click)="onClearAll()">
              Clear All
            </button>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
      <button type="submit" class="btn btn-primary mr-sm-2 mb-1">Send</button>
      <button
        (click)="onResetForm()"
        type="reset"
        class="btn btn-secondary mb-1"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
