<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body" style="overflow-x: auto">
          <div class="row">
            <div class="form-group">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    [class.active]="organizerType === 'freelancer'"
                    id="freelancer-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="freelancer"
                    aria-selected="true"
                    (click)="filterOrganizationType('freelancer')"
                  >
                    Freelancer
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    [class.active]="organizerType === 'organization'"
                    id="organization-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="organization"
                    aria-selected="false"
                    (click)="filterOrganizationType('organization')"
                  >
                    Organization
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col" *ngIf="organizerType === 'organization'">Organization Title</th>
                <th scope="col">Type</th>
                <th scope="col">Online Commission</th>
                <th scope="col">Cash Commission</th>
                <th scope="col">Status</th>
                <th scope="col">Type</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let formsSubmission of formsSubmissionData">
                <td>{{ formsSubmission.id }}</td>
                <td>
                  <a
                    [routerLink]="[
                        '/pages/organizers',
                        organizerType === 'organization' ? formsSubmission?.communityId : formsSubmission?.userId,
                        { organizerType: organizerType }
                      ]"
                  >
                    {{ formsSubmission.fullName }}
                  </a>
                </td>
                <td *ngIf="organizerType === 'organization'">
                    {{ formsSubmission?.community?.title}}}
                </td>
                <td>
                  {{
                    formsSubmission.pendingType === "USER_TERMS_ACCEPTANCE"
                      ? "User"
                      : "Admin"
                  }}
                </td>
                <td>{{ formsSubmission.commission }}</td>
                <td>{{ formsSubmission.cashCommission }}</td>
                <td>
                  <span
                    class="text-success"
                    *ngIf="formsSubmission.status === 'Accepted'"
                  >
                    {{ formsSubmission.status }}
                  </span>
                  <span
                    class="text-danger"
                    *ngIf="formsSubmission.status === 'Rejected'"
                  >
                    {{ formsSubmission.status }}
                  </span>
                  <span
                    class="text-warning"
                    *ngIf="formsSubmission.status === 'Pending'"
                  >
                    {{ formsSubmission.status }}
                  </span>
                </td>
                <td>{{ formsSubmission.ownershipType === 'EVENT' ? 'Freelance' : 'Organization' }}</td>
                <td>
                  <button style="background-color: transparent; border: none">
                    <a
                      [routerLink]="[
                        '/pages/organizers',
                        organizerType === 'organization' ? formsSubmission?.communityId : formsSubmission?.userId,
                        { organizerType: organizerType }
                      ]"
                    >
                      <i class="ft-edit text-primary cursor-pointer"></i>
                    </a>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <ngb-pagination
              [collectionSize]="formsSubmissionMeta?.total"
              (pageChange)="pageChange($event)"
              [(page)]="page"
              pageSize="10"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="true"
              [boundaryLinks]="false"
            ></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
