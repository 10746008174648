import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormSubmissionService } from "./service/form-submission.service";
import { ToastrService } from "ngx-toastr";
import { FormSubmission } from "../../models/form-submission.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Community } from "app/pages/full-pages/communities/models/communities.model";
import { CommunityService } from "app/pages/full-pages/communities/service/community.service";
import ResponseWrapper from "app/shared/models/response.models";
import { NgxSpinnerService } from "ngx-spinner";
import { InvoiceService } from "app/pages/full-pages/invoices-history/invoice.service";
import Invoice from "app/pages/full-pages/invoices-history/models/invoices.model";

@Component({
  selector: "app-form-submission",
  templateUrl: "./form-submission.component.html",
  styleUrls: ["./form-submission.component.scss"],
})
export class FormSubmissionComponent implements OnInit {
  userId: number;
  organizerType: string;
  formSubmission: FormSubmission;
  formSubmissionId: number;
  requiredField = true;
  page = 1;
  pageSize: number;
  searchValue: string;
  invoices: Invoice[];
  updateForm = new FormGroup({
    isApproved: new FormControl(false, [Validators.required]),
    commission: new FormControl(0, [Validators.required]),
    cashCommission: new FormControl(0, [Validators.required]),
    reason: new FormControl("", [Validators.required]),
  });
  constructor(
    private route: ActivatedRoute,
    private formSubmissionService: FormSubmissionService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private communityService: CommunityService,
    private invoiceService: InvoiceService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userId = +params["id"];
      this.organizerType = params['organizerType'];
    });
    this.formSubmissionService
      .getFormSubmission(this.userId, this.organizerType)
      .subscribe((response: FormSubmission) => {
        this.formSubmission = response;
        this.updateForm.patchValue({ ...this.formSubmission });
        this.cdr.markForCheck();
      });

    this.invoiceService
      .getOrganizerInvoicesHistory(this.userId, this.organizerType)
      .subscribe((response: ResponseWrapper<Invoice>) => {
        this.invoices = response.data;
        this.pageSize = response.meta.total;
      });
  }

  updateSubmissionForm() {
    this.formSubmissionService
      .updateSubmissionForm(this.formSubmission.id, this.updateForm.value)
      .subscribe(
        (response) => {
          console.log('response', response)
          this.toastr.success("Success", "Updated successfully");
          this.cdr.markForCheck();
          this.formSubmissionService
            .getFormSubmission(this.userId, this.organizerType)
            .subscribe((response: FormSubmission) => {
              this.formSubmission = response;
              this.cdr.markForCheck();
            });
        },
        (err) => {
          console.log(err);
          err.message && this.toastr.error(err.message, "Update Faild");
          err.error.message.errors.map((error) => {
            this.toastr.error(error.message, "Update Faild");
          });
        }
      );
  }

  pageChange(pageNumber = 1) {
    this.spinner.show();

    this.invoiceService
      .getOrganizerInvoicesHistory(this.userId, this.organizerType, { page: pageNumber })
      .subscribe((response: ResponseWrapper<Invoice>) => {
        this.spinner.hide();
        this.invoices = response.data;
        this.pageSize = response.meta.per_page;
      });
  }
}
