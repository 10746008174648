import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import ResponseWrapper from "app/shared/models/response.models";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Community } from "../models/communities.model";
import { CommunityService } from "../service/community.service";

@Component({
  selector: "app-communities-list",
  templateUrl: "./communities-list.component.html",
  styleUrls: ["./communities-list.component.scss"],
})
export class CommunitiesComponent implements OnInit {
  page = 1;
  pageSize: number;
  searchValue: string;
  communities: Community[];
  organizerType = 'freelancer';


  constructor(
    private communityService: CommunityService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,

    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.communityService
      .getCommunities(this.organizerType)
      .subscribe((response: ResponseWrapper<Community>) => {
        this.communities = response.data;
        this.pageSize = response?.meta?.total;
        this.cdr.markForCheck();
        console.log({ communities: response.data });
      });
  }
  pageChange(pageNumber = 1) {
    const options: { page?: number; search?: string } = {
      page: pageNumber,
    };

    if (this.searchValue) {
      options.search = this.searchValue;
    }
    this.spinner.show();

    this.communityService
      .getCommunities(this.organizerType,options)
      .subscribe((response: ResponseWrapper<Community>) => {
        this.communities = response.data;
        this.pageSize = response?.meta?.total;
        this.cdr.markForCheck();
        this.spinner.hide();
      });
  }

  update(communityId, value) {
    this.spinner.show();

    this.communityService.updateCommunityState(communityId, value).subscribe(
      (_) => {
        const community = this.communities.find(
          (community) => community.id == communityId
        );
        community.state = value;

        this.spinner.hide();
        this.toastr.success("State updated successfully");
        this.cdr.markForCheck();
      },
      (err) => {
        this.spinner.hide();
        err.message &&
          this.toastr.error(err.message, "Failed to update community state");
      }
    );
  }

  search(event) {
    this.spinner.show();
    this.searchValue = event.target.value;

    if (this.searchValue) {
      this.communityService
        .getCommunities(this.organizerType, {
          page: 1,
          search: event.target.value,
        })
        .subscribe((response) => {
          this.communities = response.data;
          this.pageSize = response?.meta?.total;
          this.spinner.hide();
        });
    } else {
      this.communityService
        .getCommunities(this.organizerType, {
          page: 1,
        })
        .subscribe((response) => {
          this.communities = response.data;
          this.pageSize = response?.meta?.total;
          this.spinner.hide();
        });
    }
  }

  filterOrganizationType(selectedValue: string) {
    // Update the organizerType property with the selected text value
    this.organizerType = selectedValue;
    // Fetch data based on the selected value
    this.pageChange();
  }

}
