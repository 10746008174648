<nav
  class="navbar navbar-expand-lg navbar-light header-navbar {{
    transparentBGClass
  }}"
>
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div
        class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse"
        (click)="toggleSidebar()"
      >
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a
            class="nav-link apptogglefullscreen"
            id="navbar-fullscreen"
            href="javascript:;"
            appToggleFullscreen
            (click)="ToggleClass()"
            ><i class=" {{ toggleClass }} font-medium-3"></i
          ></a>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo">
                <a
                  class="logo-text"
                  [routerLink]="['/dashboard']"
                  *ngIf="config?.layout.menuPosition === 'Top'"
                >
                  <div class="logo-img">
                    <img class="logo-img" alt="Apex logo" [src]="logoUrl" />
                  </div>
                  <span class="text">APEX</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li
            class="dropdown nav-item"
            placement="bottom-left"
            display="static"
            ngbDropdown
          ></li>
          <li
            class="dropdown nav-item mr-1"
            placement="bottom-left"
            display="static"
            ngbDropdown
          >
            <a
              class="nav-link dropdown-toggle user-dropdown d-flex align-items-end"
              id="dropdownBasic2"
              href="javascript:;"
              data-toggle="dropdown"
              ngbDropdownToggle
            >
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right"
                  >{{ adminProfile?.firstName
                  }}{{ adminProfile?.lastName }}</span
                >
                <span>Admin</span>
              </div>
              <img
                class="avatar"
                [src]="
                  'https://ik.imagekit.io/tnc8pizf9e/' +
                  adminProfile?.profilePicture +
                  '?tr=w-40,h-40'
                "
                alt="avatar"
                height="40"
                width="40"
              />
            </a>
            <div
              class="dropdown-menu text-left dropdown-menu-right m-0 pb-0"
              aria-labelledby="dropdownBasic2"
              ngbDropdownMenu
            >
              <a class="dropdown-item" routerLink="/pages/account-settings">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Edit Profile</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
