<div class="row">
  <div class="col-12">
    <div class="content-header">Notifications</div>
    <p class="content-sub-header mb-1">
      Send notifications based on audience selection.
    </p>
  </div>
</div>
<div class="row">
  <div class="col-md-3 mt-3">
    <!-- Nav tabs -->
    <ul class="nav flex-column nav-pills" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          href="javascript:;"
          [ngClass]="{ active: activeTab === 'notify' }"
          (click)="setActiveTab('notify')"
          id="notify-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="notify"
          aria-selected="false"
        >
          <i class="ft-info mr-1 align-middle"></i>
          <span class="align-middle">Notify</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="col-md-9">
    <!-- Tab panes -->
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="tab-content">
            <!--Notify Tab -->
            <div
              class="tab-pane"
              [ngClass]="{ active: activeTab === 'notify' }"
              id="notify"
              role="tabpanel"
              aria-labelledby="notify-tab"
            >
              <app-notify></app-notify>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
