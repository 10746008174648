<!-- Invoice template starts -->
<div class="row">
  <div class="col-12">
    <div class="content-header">Form Submission ({{organizerType.toLocaleUpperCase()}})</div>
  </div>
</div>
<section class="invoice-template">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-content p-3">
          <div id="invoice-template" class="card-body pb-0">
            <!-- Invoice Items Details starts -->
            <div id="invoice-items-details">
              <div class="row mt-3 mt-md-0">
                <div class="col-md-6 col-12 text-left">
                  <p class="text-bold-700 mb-1 ml-1">Details:</p>
                  <div class="row">
                    <div class="col-12" style="overflow-x: auto">
                      <table class="table table-borderless table-sm">
                        <tbody>
                          <tr>
                            <td>Bank name:</td>
                            <td class="text-right">
                              {{ formSubmission?.bankName }}
                            </td>
                          </tr>
                          <tr>
                            <td>Bank Address:</td>
                            <td class="text-right">
                              {{ formSubmission?.bankAddress }}
                            </td>
                          </tr>
                          <tr>
                            <td>Account Number:</td>
                            <td class="text-right">
                              {{ formSubmission?.bankAccountNumber }}
                            </td>
                          </tr>
                          <tr>
                            <td>Account Email:</td>
                            <td class="text-right">
                              {{ formSubmission?.email }}
                            </td>
                          </tr>
                          <tr>
                            <td>Account Phone Number:</td>
                            <td class="text-right">
                              {{ formSubmission?.phoneNumber }}
                            </td>
                          </tr>
                          <tr>
                            <td>Account Name:</td>
                            <td class="text-right">
                              {{ formSubmission?.fullName }}
                            </td>
                          </tr>
                          <tr>
                            <td>IBAN:</td>
                            <td class="text-right">
                              {{ formSubmission?.iban }}
                            </td>
                          </tr>
                          <tr>
                            <td>SWIFT code:</td>
                            <td class="text-right">
                              {{ formSubmission?.swiftCode }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <p class="text-bold-700 mb-2 ml-4">Identity</p>
                  <div class="row">
                    <div class="col-4" *ngIf="formSubmission?.nationalIdFront">
                      <a
                        [href]="
                          'https://ik.imagekit.io/tnc8pizf9e/' +
                          formSubmission?.nationalIdFront
                        "
                        target="_blank"
                      >
                        <img
                          [src]="
                            'https://ik.imagekit.io/tnc8pizf9e/' +
                            formSubmission?.nationalIdFront +
                            '?tr=w-200,h-200'
                          "
                          alt="national id front"
                          title="national id front"
                        />
                      </a>
                    </div>

                    <div class="col-2"></div>
                    <div class="col-4" *ngIf="formSubmission?.nationalIdBack">
                      <a
                        [href]="
                          'https://ik.imagekit.io/tnc8pizf9e/' +
                          formSubmission?.nationalIdBack
                        "
                        target="_blank"
                      >
                        <img
                          [src]="
                            'https://ik.imagekit.io/tnc8pizf9e/' +
                            formSubmission?.nationalIdBack +
                            '?tr=w-200,h-200'
                          "
                          alt="national id back"
                          title="national id back"
                      /></a>
                    </div>
                  </div>

                  <div
                    *ngIf="formSubmission?.passport"
                    style="margin-top: 20px"
                  >
                    <a
                      [href]="
                        'https://ik.imagekit.io/tnc8pizf9e/' +
                        formSubmission?.passport
                      "
                      target="_blank"
                    >
                      <img
                        [src]="
                          'https://ik.imagekit.io/tnc8pizf9e/' +
                          formSubmission?.passport +
                          '?tr=w-200,h-200'
                        "
                        alt="passport"
                        title="passport"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Invoice Items Details ends -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Invoice template ends -->

<!-- Basic Inputs start -->
<section id="basic-input">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Basic</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form [formGroup]="updateForm">
              <div class="row">
                <div class="col-md-6">
                  <fieldset class="form-group">
                    <label for="commission">Online Payments Commission</label>
                    <input
                      formControlName="commission"
                      type="number"
                      class="form-control"
                      id="commission"
                      placeholder="Online Payments Commission"
                    />
                  </fieldset>
                </div>
                <div class="col-md-6">
                  <fieldset class="form-group">
                    <label for="cashCommission">Cash Payments Commission</label>
                    <input
                      formControlName="cashCommission"
                      type="number"
                      class="form-control"
                      id="cashCommission"
                      placeholder="Cash Payments Commission"
                    />
                  </fieldset>
                </div>
                <div class="col-md-6">
                  <label for="isApproved">Form Status</label>
                  <fieldset class="form-group">
                    <div
                      class="custom-switch custom-control-inline mb-1 mb-xl-0"
                    >
                      <input
                        formControlName="isApproved"
                        type="checkbox"
                        class="custom-control-input"
                        id="isApproved"
                      />
                      <label class="custom-control-label mr-1" for="isApproved">
                        <span>Status</span>
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <fieldset class="form-group">
                    <label for="reason">Comment</label>
                    <textarea
                      formControlName="reason"
                      class="form-control"
                      id="reason"
                      required
                      rows="3"
                    ></textarea>
                    <!-- <span
                      class="text-danger"
                      *ngIf="
                        updateForm.controls['isApproved'].value != true &&
                        updateForm.controls['reason'].value != ''
                      "
                      >Required</span
                    > -->
                  </fieldset>
                </div>
              </div>

              <!-- Invoice Footer starts -->
              <div id="invoice-footer">
                <div class="row">
                  <div class="col-12 text-center text-sm-right">
                    <button
                      type="button"
                      class="btn btn-primary btn-print mt-2 mt-md-1"
                      (click)="updateSubmissionForm()"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- Invoice Footer ends -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Basic Inputs end -->

<!-- Invoices History -->
<section id="basic-input">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Invoices History</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-content">
                    <div
                      class="card-body table-responsive col-12"
                      style="overflow-x: auto"
                    >
                      <table class="table">
                        <thead style="white-space: nowrap">
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Event</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Cash Amount</th>
                            <th scope="col">Online Amount</th>
                            <th scope="col">Online Commission</th>
                            <th scope="col">Cash Commission</th>
                            <th scope="col">Status</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Earnings</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let invoice of invoices"
                            style="white-space: nowrap"
                          >
                            <td>{{ invoice?.id }}</td>
                            <td>
                              <a
                                [routerLink]="[
                                  '/pages/events/',
                                  invoice?.event.id
                                ]"
                              >
                                {{ invoice?.event?.title }}
                              </a>
                            </td>
                            <td>
                              {{ invoice?.amount }}
                            </td>
                            <td>
                              {{ invoice?.cashPaymentAmount }}
                            </td>
                            <td>
                              {{ invoice?.onlinePaymentAmount }}
                            </td>
                            <td>
                              {{ invoice?.commission }}
                            </td>
                            <td>
                              {{ invoice?.cashCommission }}
                            </td>
                            <td>
                              <span
                                class="text-success"
                                *ngIf="invoice?.status === 'COMPLETED'"
                              >
                                Completed
                              </span>
                              <span
                                class="text-danger"
                                *ngIf="invoice?.status === 'CANCELLED'"
                              >
                                Cancelled
                              </span>
                              <span
                                class="text-warning"
                                *ngIf="invoice?.status === 'REFUNDED'"
                              >
                                REFUNDED
                              </span>
                            </td>
                            <td>
                              {{ invoice?.unitPrice }}
                            </td>
                            <td>
                              {{ invoice?.netEarning || invoice?.earnings }}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div>
                        <ngb-pagination
                          [collectionSize]="pageSize"
                          (pageChange)="pageChange($event)"
                          [(page)]="page"
                          pageSize="10"
                          [maxSize]="5"
                          [rotate]="true"
                          [ellipses]="true"
                          [boundaryLinks]="false"
                        ></ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Invoice Footer ends -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Invoices History end -->
