import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Event } from "../models/events.model";
import { EventsService } from "../service/event.service";

@Component({
  selector: "app-community-page",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"],
})
export class EventComponent implements OnInit {
  eventId: number;
  event: Event;

  constructor(
    private route: ActivatedRoute,
    private eventService: EventsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.eventId = +params["eventId"];
    });

    this.eventService.getEvent(this.eventId).subscribe((response: Event) => {
      this.event = response;
      console.log({ response });
      this.cdr.markForCheck();
    });
  }
}
