<div class="col justify-content-center">
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <h5 class="card-title">Revenue Data</h5>
        <form [formGroup]="dateRangeForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-6 form-group">
              <label for="fromDate">From Date</label>
              <input
                type="date"
                id="fromDate"
                formControlName="fromDate"
                class="form-control"
                required
                [min]="minDate"
              />
            </div>
            <div class="col-6 form-group">
              <label for="toDate">To Date</label>
              <input
                type="date"
                id="toDate"
                formControlName="toDate"
                class="form-control"
                required
                [min]="minDate"
              />
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="loading"
              >
                Get Revenue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="mt-4" *ngIf="communitiesRevenue?.length > 0">
  <h3 class="">Exporting an excel file of type .xslx !!!</h3>
  <p>Export as XLSX by clicking the below button</p>
  <button (click)="exportAsXLSX()">
    <i class="fa fa-file-excel-o" style="font-size: 28px; color: blue"></i>
  </button>
</div>

<div *ngIf="communitiesRevenue?.length > 0" class="row mt-4">
  <div class="col-6 form-group">
    <h5 for="fromDate">From:</h5>
    <h4>{{ from }}</h4>
  </div>
  <div class="col-6 form-group">
    <h5 for="toDate">To:</h5>
    <h4>{{ to }}</h4>
  </div>
</div>

<div *ngIf="communitiesRevenue?.length > 0" class="mt-4">
  <table class="table">
    <thead>
      <tr>
        <th>Community ID</th>
        <th>Community Name</th>
        <th>Online</th>
        <th>Cash</th>
        <th>Cash Over</th>
        <th>Total Revenue</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of communitiesRevenue">
        <td>{{ item.communityId }}</td>
        <td>{{ item.communityName }}</td>
        <td>{{ item.onlinePaymentAmount }}</td>
        <td>{{ item.cashPaymentAmount }}</td>
        <td>{{ item.cashOverAmount }}</td>
        <td>{{ item.totalRevenue | currency : " AED" : "symbol" }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div>
  <ngb-pagination
    [collectionSize]="pageSize"
    (pageChange)="pageChange($event)"
    [(page)]="page"
    pageSize="5"
    [maxSize]="5"
    [rotate]="true"
    [ellipses]="true"
    [boundaryLinks]="false"
  ></ngb-pagination>
</div>
<!-- </section> -->

<ngx-spinner></ngx-spinner>
