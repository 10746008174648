import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CommunityService } from "../communities/service/community.service";
import { environment } from "environments/environment";
import { EventsService } from "../events/service/event.service";
import { TokenService } from "app/shared/auth/token.service";
import EntitiesResponse, { EntityDataResponse } from "./models/entities.model";
import { UserService } from "../users/users.service";
import { EntityType } from "app/shared/enums/entity-type.enum";
import { EventAudience } from "app/shared/enums";
import { TagService } from "../tags/tags.service";
import { CommunityAudience } from "app/shared/enums/community-audience.enum";
import { UserAudience } from "app/shared/enums/user-audience.enum";
import { TagAudience } from "app/shared/enums/tag-audience.enum";

@Injectable({ providedIn: "root" })
export class NotificationsService {
  response: Observable<any>;

  audienceType: any[] = [];

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService,
    private readonly communityService: CommunityService,
    private readonly eventService: EventsService,
    private readonly userService: UserService,
    private readonly tagService: TagService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: this.tokenService.getToken(),
    };
    return new HttpHeaders(headersConfig);
  }

  sendNotification(body) {
    return this.httpClient.post(
      `${environment.apiUrl}/dashboard/notifications`,
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }

  getEntitiesByType(
    entityType: EntityType,
    options?: { page?: number; search?: string }
  ): Observable<EntitiesResponse> {
    switch (entityType) {
      case EntityType.EVENT:
        this.response = this.eventService.getEvents(options).pipe(
          map((response) => {
            return {
              data: response.data.map((entity) => ({
                id: entity.id,
                title: entity.title,
              })),
              meta: response.meta,
            };
          }),
          catchError((error) => {
            throw Observable.throw(error);
          })
        );
        break;

      case EntityType.COMMUNITY:
        this.response = this.communityService.getCommunities('freelancer', options).pipe(
          map((response) => {
            return {
              data: response.data.map((entity) => ({
                id: entity.id,
                title: entity.title,
              })),
              meta: response.meta,
            };
          }),
          catchError((error) => {
            throw Observable.throw(error);
          })
        );

        break;

      case EntityType.USER:
        this.response = this.userService.getUsers(options).pipe(
          map((response) => {
            return {
              data: response.data.map((entity) => ({
                id: entity.id,
                title: `${entity.first_name} ${entity.last_name}`,
              })),
              meta: response.meta,
            };
          }),
          catchError((error) => {
            throw Observable.throw(error);
          })
        );
        break;

      case EntityType.TAG:
        this.response = this.tagService.getTags(options).pipe(
          map((response) => {
            return {
              data: response.data.map((entity) => ({
                id: entity.id,
                title: entity.title,
              })),
              meta: response.meta,
            };
          }),
          catchError((error) => {
            throw Observable.throw(error);
          })
        );
        break;

      default:
        throw new Error("Invalid or not supported entity type");
    }

    return this.response;
  }

  getAudienceByType(entityType: EntityType) {
    switch (entityType) {
      case EntityType.EVENT:
        this.audienceType = [
          { name: EventAudience.EventUsers, value: 1 },
          { name: EventAudience.EventOwner, value: 2 },
          { name: EventAudience.WaitingList, value: 3 },
        ];
        break;

      case EntityType.COMMUNITY:
        this.audienceType = [
          {
            name: CommunityAudience[CommunityAudience.CommunityUsers],
            value: CommunityAudience.CommunityUsers,
          },
          {
            name: CommunityAudience[CommunityAudience.CommunityCaptains],
            value: CommunityAudience.CommunityCaptains,
          },
          {
            name: CommunityAudience[CommunityAudience.CommunityOwner],
            value: CommunityAudience.CommunityOwner,
          },
        ];
        break;

      case EntityType.USER:
        this.audienceType = [
          { name: UserAudience[UserAudience.User], value: UserAudience.User },
        ];
        break;

      case EntityType.TAG:
        this.audienceType = [
          { name: TagAudience[TagAudience.User], value: TagAudience.User },
          {
            name: TagAudience[TagAudience.CommunityUsers],
            value: TagAudience.CommunityUsers,
          },
          {
            name: TagAudience[TagAudience.CommunityCaptains],
            value: TagAudience.CommunityCaptains,
          },
          {
            name: TagAudience[TagAudience.CommunityOwner],
            value: TagAudience.CommunityOwner,
          },
        ];
        break;
    }

    return this.audienceType;
  }
}
