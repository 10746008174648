import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import ResponseWrapper from "app/shared/models/response.models";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Event } from "../models/events.model";
import { EventsService } from "../service/event.service";

@Component({
  selector: "app-events-list",
  templateUrl: "./events-list.component.html",
  styleUrls: ["./events-list.component.scss"],
})
export class EventsComponent implements OnInit {
  page = 1;
  pageSize: number;
  events: Event[];
  searchValue: string;

  enable = true;
  constructor(
    private eventsService: EventsService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,

    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();

    this.eventsService
      .getEvents()
      .subscribe((response: ResponseWrapper<Event>) => {
        this.events = response.data;
        this.pageSize = response?.meta?.total;
        this.cdr.markForCheck();
        console.log({ events: response.data });
        this.spinner.hide();
      });
  }
  pageChange(pageNumber = 1) {
    const options: { page?: number; search?: string } = {
      page: pageNumber,
    };

    if (this.searchValue) {
      options.search = this.searchValue;
    }
    this.spinner.show();

    this.eventsService
      .getEvents(options)
      .subscribe((response: ResponseWrapper<Event>) => {
        this.events = response.data;
        this.pageSize = response?.meta?.total;
        this.cdr.markForCheck();
        this.spinner.hide();
      });
  }

  onEventFeaturedValueChange(value, eventId) {
    console.log(eventId);
    console.log(value);
    this.spinner.show();

    this.eventsService.featureEvent(eventId).subscribe(
      (_) => {
        this.spinner.hide();
        this.toastr.success("State updated successfully");
        this.cdr.markForCheck();
      },
      (err) => {
        this.spinner.hide();
        err.message && this.toastr.error(err.message, "Failed to update event");
      }
    );
  }

  onEventCancelValueChange(eventId) {
    this.spinner.show();

    this.eventsService.cancelEvent(eventId).subscribe(
      (_) => {
        this.spinner.hide();
        this.toastr.success("Event Cancelled successfully");
        this.cdr.markForCheck();
      },
      (err) => {
        this.spinner.hide();
        err.message && this.toastr.error(err.message, "Failed to update event");
      }
    );
  }
  search(event) {
    this.spinner.show();
    this.searchValue = event.target.value;

    if (this.searchValue) {
      this.eventsService
        .getEvents({
          page: 1,
          search: event.target.value,
        })
        .subscribe((response) => {
          this.events = response.data;
          this.pageSize = response?.meta?.total;
          this.spinner.hide();
        });
    } else {
      this.eventsService
        .getEvents({
          page: 1,
        })
        .subscribe((response) => {
          this.events = response.data;
          this.pageSize = response?.meta?.total;
          this.spinner.hide();
        });
    }
  }
}
