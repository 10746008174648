import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import firebase from "firebase/app";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { TokenService } from "./token.service";

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(
    private httpClient: HttpClient,
    public router: Router,
    private tokenService: TokenService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    return new HttpHeaders(headersConfig);
  }
  signinUser(body: { email: string; password: string }) {
    return this.httpClient.post(
      `${environment.apiUrl}/dashboard/auth/login`,
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }

  resetPassword(
    body: {
      password: string;
      confirmPassword: string;
    },
    resetPasswordToken: string
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}/dashboard/auth/reset-password?token=${resetPasswordToken}`,
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }

  sendForgotPaswordRequest(body: { email: string }) {
    return this.httpClient.post(
      `${environment.apiUrl}/dashboard/auth/forgot-password`,
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }
  logout() {
    this.tokenService.removeToken();
    this.router.navigate(["pages/login"]);
  }

  isAuthenticated() {
    const token = this.tokenService.getToken();
    if (token) return true;
    else return false;
  }
}
