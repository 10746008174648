import { RouteInfo } from './vertical-menu.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Invoices History',
    icon: 'ft-copy',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/pages/users-earning',
        title: 'Users Earings',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/pages/packages',
        title: 'Packages',
        icon: 'ft-copy',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/pages/item-invoice',
        title: 'Products',
        icon: 'ft-copy',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Users',
    icon: 'ft-copy',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/pages/organizers',
        title: 'Organizers',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: ' badge badge-pill badge-danger',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/pages/account-settings',
    title: 'Account Settings',
    icon: 'ft-copy',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  // {
  //   path: '/pages/communities',
  //   title: 'Communities',
  //   icon: 'ft-copy',
  //   class: '',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  // },
  {
    path: '',
    title: 'Communities',
    icon: 'ft-copy',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/pages/communities',
        title: 'Communities List',
        icon: 'ft-copy',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/pages/communities/revenue/list',
        title: 'Communities Revenue',
        icon: 'ft-copy',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/pages/charities',
    title: 'Charities',
    icon: 'ft-copy',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/pages/events',
    title: 'Events',
    icon: 'ft-copy',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/pages/notifications',
    title: 'Notifications',
    icon: 'ft-copy',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/pages/tiers',
    title: 'Tiers',
    icon: 'ft-copy',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
];
