import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from 'app/shared/auth/token.service';
import ResponseWrapper from 'app/shared/models/response.models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {TiersList} from '../tiers.model';

@Injectable({
  providedIn: 'root',
})
export class TiersListService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: this.tokenService.getToken(),
    };
    return new HttpHeaders(headersConfig);
  }

  getAllTiers(organizerType?, options?: { page: number; }): Observable<ResponseWrapper<TiersList>> {
    const url = !organizerType || organizerType === 'freelancer'  ? `dashboard/tier` : `dashboard/organization-tier`;
    return this.httpClient.get<ResponseWrapper<TiersList>>(
      `${environment.apiUrl}/${url}?page=${
        options?.page || 1
      }`,
      {
        headers: this.setHeaders(),
      }
    );
  }
  addTier(organizerType: string, body: Object = {}) {
    const path = organizerType === 'freelancer' ? 'dashboard/tier' : 'dashboard/organization-tier';
    return this.httpClient.post(`${environment.apiUrl}/${path}`, body, {
      headers: this.setHeaders(),
    });
  }
  updateTier(organizerType: string, body: Object = {}) {
    const path = organizerType === 'freelancer' ? `dashboard/tier/${body['id']}` : `dashboard/organization-tier/${body['id']}`;
    return this.httpClient.patch(`${environment.apiUrl}/${path}`, body, {
      headers: this.setHeaders(),
    });
  }
  delete(path: string) {
    return this.httpClient.delete(`${environment.apiUrl}${path}`, {
      headers: this.setHeaders(),
    });
  }
}
