import { Component, Input, Output, EventEmitter } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal-styles.component.scss']
})

export class ModalComponent {
  @Input() showModal: boolean;
  @Input() modalTitle: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  // // formData: any = {name: '', from: '', to: null, commissionPercentage: null};
  // formData = new FormGroup({
  //   name: new FormControl(false, [Validators.required]),
  //   from: new FormControl(0, [Validators.required]),
  //   to: new FormControl(0, [Validators.required]),
  //   commissionPercentage: new FormControl(0, [Validators.required]),
  // });
  constructor() {}

  onCloseModal() {
    this.closeModal.emit();
  }
}
