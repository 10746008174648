import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ResponseMetadata } from "app/shared/models";
import ResponseWrapper from "app/shared/models/response.models";
import { FormSubmission } from "../../models/form-submission.model";

import { FormsSubmissionService } from "./service/forms-submission-list.service";

@Component({
  selector: "app-forms-submission-list",
  templateUrl: "./forms-submission-list.component.html",
  styleUrls: ["./forms-submission-list.component.scss"],
})
export class FormsSubmissionComponent implements OnInit {
  formsSubmissionData: FormSubmission[];
  formsSubmissionMeta: ResponseMetadata;
  page = 1;
  pageSize: number;
  organizerType = 'freelancer';
  constructor(
    private readonly formsSubmissionService: FormsSubmissionService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.formsSubmissionService
      .getAllFormsSubmission(this.organizerType)
      .subscribe((response: ResponseWrapper<FormSubmission>) => {
        this.formsSubmissionData = response.data;
        this.formsSubmissionMeta = response.meta;
        this.cdr.markForCheck();
      });
  }
  pageChange(pageNumber = 1) {
    const options = {
      page: pageNumber,
    };
    this.formsSubmissionService
      .getAllFormsSubmission(this.organizerType, options)
      .subscribe((response: ResponseWrapper<FormSubmission>) => {
        this.formsSubmissionData = response.data;
        this.formsSubmissionMeta = response.meta;
        this.cdr.markForCheck();
      });
  }

  filterOrganizationType(selectedValue: string) {
    // Update the organizerType property with the selected text value
    this.organizerType = selectedValue;
    // Fetch data based on the selected value
    this.pageChange();
  }
}
