import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenService } from "app/shared/auth/token.service";
import ResponseWrapper from "app/shared/models/response.models";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { Event } from "../models/events.model";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: this.tokenService.getToken(),
    };
    return new HttpHeaders(headersConfig);
  }

  getEvents(options?: {
    page?: number;
    search?: string;
  }): Observable<ResponseWrapper<Event>> {
    return this.httpClient.get<ResponseWrapper<Event>>(
      `${environment.apiUrl}/dashboard/events`,
      {
        headers: this.setHeaders(),
        params: options,
      }
    );
  }

  getEvent(eventId: number): Observable<Event> {
    return this.httpClient.get<Event>(
      `${environment.apiUrl}/dashboard/events/${eventId}`,
      {
        headers: this.setHeaders(),
      }
    );
  }

  updateEvent(path: string, body: Object = {}) {
    return this.httpClient.put(
      `${environment.apiUrl}/dashboard/events/${path}`,
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }
  featureEvent(eventId: string) {
    return this.httpClient.patch(
      `${environment.apiUrl}/dashboard/events/${eventId}/feature`,
      {},
      {
        headers: this.setHeaders(),
      }
    );
  }
  cancelEvent(eventId: string) {
    return this.httpClient.patch(
      `${environment.apiUrl}/dashboard/events/${eventId}/cancel`,
      {},
      {
        headers: this.setHeaders(),
      }
    );
  }
}
