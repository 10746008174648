<!-- Filter Datatables -->
<!-- <section id="ngx-datatable-filter"> -->
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body" style="overflow-x: auto">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
<!--                <th scope="col">Organization Name</th>-->
                <th scope="col">Owner</th>
                <th scope="col">Earnings</th>
                <th scope="col">Total Invoices</th>
                <th scope="col">Payout</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let organizationEarningInvoice of organizerEarningData">
                <td>{{ organizationEarningInvoice.id }}</td>
<!--                <td>{{ organizationEarningInvoice.first_name }}</td>-->
                <td>
                  <a
                    [routerLink]="[
                        '/pages/item-invoice',
                        organizationEarningInvoice.id,
                      ]"
                  >
                    {{ organizationEarningInvoice.first_name }}
                    {{ organizationEarningInvoice.last_name }}
                  </a>
                </td>
                <td>{{ organizationEarningInvoice.earnings }}</td>
                <td>{{ organizationEarningInvoice.totalInvoices }}</td>
                <td>
                  <button style="background-color: transparent; border: none">
                    <a
                      [routerLink]="[
                        '/pages/item-invoice',
                        organizationEarningInvoice.id,
                      ]"
                    >
                      <i class="ft-edit text-primary cursor-pointer"></i>
                    </a>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <ngb-pagination
              [collectionSize]="pageSize"
              (pageChange)="pageChange($event)"
              [(page)]="page"
              pageSize="10"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="true"
              [boundaryLinks]="false"
            ></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </section> -->
<!--/ Filter Datatables -->
