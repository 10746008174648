import {
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import EntitiesResponse, { EntityDataResponse } from "../models/entities.model";
import { NotificationsService } from "../notifications.service";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  catchError,
  merge,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import { EntityType } from "app/shared/enums";

@Component({
  selector: "app-notifications-modal-content",
  templateUrl: "./notifications-modal-content.component.html",
  styleUrls: ["./notifications-modal-content.component.scss"],
})
export class NotificationsEntityModalContentComponent implements OnInit {
  @Input() selectType: "single" | "multi" = "single";

  @Input() entityType: EntityType;

  entitiesData: EntityDataResponse[] = [];

  selectedEntity: EntityDataResponse;

  selectedEntities: EntityDataResponse[] = [];

  page = 1;
  pageSize: number;
  public model: any;
  modelWiki: any;
  modelTemp: any;
  searching = false;
  searchFailed = false;

  searchValue: string;
  constructor(
    public activeModal: NgbActiveModal,
    private readonly notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,

    private spinner: NgxSpinnerService
  ) {}
  ngOnInit(): void {
    console.log({
      [NotificationsEntityModalContentComponent.name]: this.entityType,
    });
    this.notificationsService
      .getEntitiesByType(this.entityType)
      .subscribe((response: EntitiesResponse) => {
        this.pageSize = response?.meta?.total;
        this.entitiesData = response.data;
        this.cdr.markForCheck();
      });
  }

  pageChange(pageNumber = 1) {
    const options: { page?: number; search?: string } = {
      page: pageNumber,
    };

    if (this.searchValue) {
      options.search = this.searchValue;
    }

    this.notificationsService
      .getEntitiesByType(this.entityType, options)
      .subscribe((response: EntitiesResponse) => {
        this.entitiesData = response.data;
        this.pageSize = response?.meta?.total;
        this.cdr.markForCheck();
      });
  }

  onEntitySelected(entity: EntityDataResponse) {
    if (this.selectType == "single") {
      this.selectedEntity = entity;
      return this.activeModal.close(entity);
    }

    entity.checked = !entity.checked;

    if (entity.checked) {
      return this.selectedEntities.push(entity);
    } else {
      const deleteAtIndex = this.selectedEntities.findIndex(
        (entity) => entity.id === entity.id
      );

      return this.selectedEntities.splice(deleteAtIndex, 1);
    }
  }

  checkAllCheckBox(event: any) {
    this.entitiesData.forEach((entity) => {
      entity.checked = event.target.checked;
      this.selectedEntities.push(entity);
    });
  }

  onCheckboxChange(event: any) {
    const entity = this.entitiesData.find(
      (entity) => entity.id == event.target.value
    );

    entity.checked = event.target.checked;
    if (event.target.checked) {
      return this.selectedEntities.push(entity);
    }

    const deleteAtIndex = this.selectedEntities.findIndex(
      (entity) => entity.id === entity.id
    );
    return this.selectedEntities.splice(deleteAtIndex, 1);
  }

  isAllCheckBoxChecked() {
    return this.entitiesData.every((entity) => entity.checked === true);
  }

  search(event) {
    this.searchValue = event.target.value;

    if (this.searchValue) {
      this.notificationsService
        .getEntitiesByType(this.entityType, {
          page: 1,
          search: event.target.value,
        })
        .subscribe((response) => {
          this.entitiesData = response.data;
          this.pageSize = response?.meta?.total;
        });
    } else {
      this.notificationsService
        .getEntitiesByType(this.entityType, {
          page: 1,
        })
        .subscribe((response) => {
          this.entitiesData = response.data;
          this.pageSize = response?.meta?.total;
        });
    }
  }
}
