import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TokenService {
  logged = new Subject<boolean>();
  tokenStorageName: string = 'token';
  constructor() {
    // this.changeloggedStatus(this.islogged());
  }

  setToken(token: string) {
    localStorage.setItem(this.tokenStorageName, token);
    this.changeloggedStatus(true);
  }
  getToken() {
    return localStorage.getItem('token');
  }

  removeToken() {
    localStorage.removeItem(this.tokenStorageName);
    this.changeloggedStatus(false);
  }
  getLoggedStatus() {
    return this.logged.asObservable();
  }
  changeloggedStatus(status: boolean) {
    this.logged.next(status);
  }

  islogged(): boolean {
    let token = localStorage.getItem('token');
    if (!token) {
      return false;
    } else return true;
  }
}
