<div class="row">
  <div class="col-12">
    <div class="content-header">Account Settings</div>
    <p class="content-sub-header mb-1">
      Configure account settings to your needs.
    </p>
  </div>
</div>
<!-- Account Settings starts -->
<div class="row">
  <div class="col-md-3 mt-3">
    <!-- Nav tabs -->
    <ul class="nav flex-column nav-pills" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          href="javascript:;"
          [ngClass]="{ active: activeTab === 'general' }"
          (click)="setActiveTab('general')"
          id="general-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="general"
          aria-selected="true"
        >
          <i class="ft-settings mr-1 align-middle"></i>
          <span class="align-middle">General</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="javascript:;"
          [ngClass]="{ active: activeTab === 'change-password' }"
          (click)="setActiveTab('change-password')"
          id="change-password-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="change-password"
          aria-selected="false"
        >
          <i class="ft-lock mr-1 align-middle"></i>
          <span class="align-middle">Change Password</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="col-md-9">
    <!-- Tab panes -->
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="tab-content">
            <!-- General Tab -->
            <div
              class="tab-pane"
              [ngClass]="{ active: activeTab === 'general' }"
              id="general"
              role="tabpanel"
              aria-labelledby="general-tab"
            >
              <div class="media">
                <img
                  *ngIf="!upload"
                  [src]="
                    'https://ik.imagekit.io/tnc8pizf9e/' +
                    gf.profilePicture.value +
                    '?tr=w-200,h-200'
                  "
                  alt="profile-img"
                  class="rounded mr-3"
                  height="64"
                  width="64"
                />
                <img
                  *ngIf="upload"
                  [src]="
                    'https://ik.imagekit.io/tnc8pizf9e/' +
                    generalForm.controls['profilePicture'].value +
                    '?tr=w-200,h-200'
                  "
                  alt="profile-img"
                  class="rounded mr-3"
                  height="64"
                  width="64"
                />
                <div class="media-body">
                  <div
                    class="col-12 d-flex flex-sm-row flex-column justify-content-start px-0 mb-sm-2"
                  >
                    <label
                      class="btn btn-sm bg-light-primary mb-sm-0"
                      for="select-files"
                      >Upload Photo</label
                    >
                    <input
                      type="file"
                      id="select-files"
                      (change)="handleFileInput($event.target.files)"
                      hidden
                    />
                    <button class="btn btn-sm bg-light-secondary ml-sm-2">
                      Reset
                    </button>
                  </div>
                  <p class="text-muted mb-0 mt-1 mt-sm-0">
                    <small>Allowed JPG, GIF or PNG</small>
                  </p>
                </div>
              </div>
              <hr class="mt-1 mt-sm-2" />
              <form [formGroup]="generalForm">
                <div class="row">
                  <div class="col-12 form-group">
                    <label for="firstName">First Name</label>
                    <div class="controls">
                      <input
                        type="text"
                        formControlName="firstName"
                        class="form-control"
                        placeholder="First Name"
                        [ngClass]="{
                          'is-invalid':
                            generalFormSubmitted && gf.firstName.invalid,
                          'is-valid':
                            generalFormSubmitted && !gf.firstName.invalid
                        }"
                        required
                      />
                      <div
                        *ngIf="
                          generalFormSubmitted &&
                          (gf.firstName.invalid ||
                            gf.firstName.errors?.required)
                        "
                        class="help-block mt-1 text-danger"
                      >
                        <i class="ft-alert-circle align-middle"></i> This is
                        required
                      </div>
                    </div>
                  </div>
                  <div class="col-12 form-group">
                    <label for="lastName">last Name</label>
                    <div class="controls">
                      <input
                        type="text"
                        formControlName="lastName"
                        class="form-control"
                        placeholder="Last Name"
                        [ngClass]="{
                          'is-invalid':
                            generalFormSubmitted && gf.lastName.invalid,
                          'is-valid':
                            generalFormSubmitted && !gf.lastName.invalid
                        }"
                        required
                      />
                      <div
                        *ngIf="
                          generalFormSubmitted &&
                          (gf.lastName.invalid || gf.lastName.errors?.required)
                        "
                        class="help-block mt-1 text-danger"
                      >
                        <i class="ft-alert-circle align-middle"></i> This is
                        required
                      </div>
                    </div>
                  </div>
                  <div class="col-12 form-group">
                    <label for="email">E-mail</label>
                    <div class="controls">
                      <input
                        type="text"
                        formControlName="email"
                        class="form-control"
                        placeholder="E-mail"
                        [ngClass]="{
                          'is-invalid':
                            generalFormSubmitted && gf.email.invalid,
                          'is-valid': generalFormSubmitted && !gf.email.invalid
                        }"
                        required
                      />
                      <div
                        *ngIf="
                          generalFormSubmitted &&
                          (gf.email.invalid || gf.email.errors?.required)
                        "
                        class="help-block mt-1 text-danger"
                      >
                        <i class="ft-alert-circle align-middle"></i> This is
                        required
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 d-flex flex-sm-row flex-column justify-content-end"
                  >
                    <button
                      (click)="onGeneralFormSubmit()"
                      class="btn btn-primary mr-sm-2 mb-1"
                    >
                      Save Changes
                    </button>
                    <button type="reset" class="btn btn-secondary mb-1">
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <!-- Change Password Tab -->
            <div
              class="tab-pane"
              [ngClass]="{ active: activeTab === 'change-password' }"
              id="change-password"
              role="tabpanel"
              aria-labelledby="change-password-tab"
            >
              <form [formGroup]="changePasswordForm">
                <div class="form-group">
                  <label for="old-password">Old Password</label>
                  <div class="controls">
                    <input
                      type="password"
                      formControlName="oldPassword"
                      class="form-control"
                      placeholder="Old Password"
                      [ngClass]="{
                        'is-invalid':
                          changePasswordFormSubmitted &&
                          cpf.oldPassword.invalid,
                        'is-valid':
                          changePasswordFormSubmitted &&
                          !cpf.oldPassword.invalid
                      }"
                      required
                    />
                    <div
                      *ngIf="
                        changePasswordFormSubmitted &&
                        (cpf.oldPassword.invalid ||
                          cpf.oldPassword.errors?.required)
                      "
                      class="help-block mt-1 text-danger"
                    >
                      <i class="ft-alert-circle align-middle"></i> This is
                      required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="newPassword">New Password</label>
                  <div class="controls">
                    <input
                      type="password"
                      formControlName="newPassword"
                      class="form-control"
                      placeholder="New Password"
                      [ngClass]="{
                        'is-invalid':
                          changePasswordFormSubmitted &&
                          cpf.newPassword.invalid,
                        'is-valid':
                          changePasswordFormSubmitted &&
                          !cpf.newPassword.invalid
                      }"
                      required
                    />
                    <div
                      *ngIf="
                        changePasswordFormSubmitted &&
                        (cpf.newPassword.invalid ||
                          cpf.newPassword.errors?.required)
                      "
                      class="help-block mt-1 text-danger"
                    >
                      <i class="ft-alert-circle align-middle"></i> This is
                      required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="confirmPassword">Retype New Password</label>
                  <div class="controls">
                    <input
                      type="password"
                      formControlName="confirmPassword"
                      class="form-control"
                      placeholder="New Password"
                      [ngClass]="{
                        'is-invalid':
                          changePasswordFormSubmitted &&
                          cpf.confirmPassword.invalid,
                        'is-valid':
                          changePasswordFormSubmitted &&
                          !cpf.confirmPassword.invalid
                      }"
                      required
                    />
                    <div
                      *ngIf="
                        changePasswordFormSubmitted &&
                        (cpf.confirmPassword.invalid ||
                          cpf.confirmPassword.errors?.required)
                      "
                      class="help-block mt-1 text-danger"
                    >
                      <i class="ft-alert-circle align-middle"></i> This is
                      required
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-sm-row flex-column justify-content-end">
                  <button
                    (click)="onChangePasswordFormSubmit()"
                    class="btn btn-primary mr-sm-2 mb-1"
                  >
                    Save Changes
                  </button>
                  <button type="reset" class="btn btn-secondary mb-1">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
<!-- Account Settings ends -->
