import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import OrganizerInvoicesSummary from '../models/organizer-invoices-summary.model';
import { InvoiceService } from '../invoice.service';

@Component({
  selector: 'app-invoice-page',
  templateUrl: './item-page.component.html',
  styleUrls: ['./item-page.component.scss'],
})
export class ItemPageComponent implements OnInit {
  id: number;
  organizerInvoicesSummary: OrganizerInvoicesSummary;
  submissionForm: any;
  invoicesIds: Array<number> = [];

  dueDate = new Date();

  isAllSelected = false;
  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
    this.invoiceService
      .getItemInvoiceSummary(this.id)
      .subscribe((response: OrganizerInvoicesSummary) => {
        this.organizerInvoicesSummary = response;
        this.submissionForm = response?.user?.organizationsSubmissionForms[0];
        // tslint:disable-next-line:max-line-length
        this.cdr.markForCheck();
      });
  }
  checkAllCheckBox(event: any) {
    this.invoicesIds = this.organizerInvoicesSummary.invoices.map((invoice) => {
      invoice.checked = event.target.checked;
      return invoice.id;
    });
  }

  onCheckboxChange(event: any) {
    const invoice = this.organizerInvoicesSummary.invoices.find(
      (invoice) => invoice.id == event.target.value
    );

    invoice.checked = event.target.checked;

    if (event.target.checked) {
      return this.invoicesIds.push(+event.target.value);
    }
    const deleteAtIndex = this.invoicesIds.findIndex(
      (invoiceId) => event.target.value === invoiceId
    );

    return this.invoicesIds.splice(deleteAtIndex, 1);
  }
  isAllCheckBoxChecked() {
    return this.organizerInvoicesSummary?.invoices.every(
      (invoice) => invoice.checked == true
    );
  }
  payout() {
    this.invoiceService
      .payout({
        isPaid: true,
        invoicesId: this.invoicesIds,
      })
      .subscribe(
        (response) => {
          this.invoicesIds = [];
          this.toastr.success('Success', 'Payout Success');
          this.cdr.markForCheck();
          this.invoiceService
            .getItemInvoiceSummary(this.id)
            .subscribe((response: OrganizerInvoicesSummary) => {
              this.organizerInvoicesSummary = response;
            });
          this.cdr.markForCheck();
        },
        (err) => {
          this.toastr.error(err.error.message, 'Payout Faild');
        }
      );
  }
}
