import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: [
    "./notifications.component.scss",
    "../../../../assets/sass/libs/select.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent implements OnInit {
  activeTab = "notify";
  constructor() {}

  ngOnInit() {}

  setActiveTab(tab) {
    this.activeTab = tab;
  }
}
