import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.scss', '../../../../../assets/sass/pages/page-users.scss']
})
export class UsersViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
