import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ResponseMetadata } from 'app/shared/models';
import ResponseWrapper from 'app/shared/models/response.models';

import {TiersList} from '../tiers.model';
import {TiersListService} from './tiers-list-service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-tiers-list',
  templateUrl: './tiers-list-temp.component.html',
  styleUrls: ['./tiers-list-styles.component.scss'],
})
export class TiersListClassComponent implements OnInit {
  tiersListData: ResponseWrapper<TiersList> | TiersList[];
  tiersListMeta: ResponseMetadata;
  page = 1;
  pageSize: number;
  organizerType = 'freelancer';
  showModal = false;
  modalTitle = this.organizerType === 'freelancer' ? 'Add Freelancer Tier' : 'Add Organizer Tier';
  formData = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    from: new FormControl('', [Validators.required]),
    to: new FormControl('', [Validators.required]),
    commissionPercentage: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly tiersListService: TiersListService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
  ) {}
  ngOnInit(): void {
    this.tiersListService
      .getAllTiers(this.organizerType)
      .subscribe((response: ResponseWrapper<TiersList>) => {
        this.tiersListData = response;
        this.tiersListMeta = response.meta;
        this.cdr.markForCheck();
      });
  }
  pageChange(pageNumber = 1) {
    const options = {
      page: pageNumber,
    };
    this.tiersListService
      .getAllTiers(this.organizerType, options)
      .subscribe((response: ResponseWrapper<TiersList>) => {
        this.tiersListData = response;
        this.tiersListMeta = response.meta;
        this.cdr.markForCheck();
      });
  }

  filterOrganizationType(selectedValue: string) {
    this.organizerType = selectedValue;
    this.pageChange();
  }

  openModal(operation: string, tier?: TiersList) {
    this.modalTitle = operation === 'edit' ? 'Edit Tier' : 'Add Tier';
    if (operation === 'edit') {
      this.formData.patchValue({ ...tier });
    }
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  handleSubmit() {
    console.log('Form submitted:', this.formData.value);
    if (this.formData.value.id) {
      this.tiersListService
        .updateTier(this.organizerType, this.formData.value)
        .subscribe(
          (response) => {
            this.toastr.success('Success', 'Updated successfully');
            this.tiersListService.getAllTiers(this.organizerType).subscribe((res: ResponseWrapper<TiersList>) => {
              this.tiersListData = res;
              this.tiersListMeta = res.meta;
              this.cdr.markForCheck();
            });
            this.showModal = false;
            this.cdr.markForCheck();
          },
          (error) => {
            this.toastr.error(error.error.message || error.message, 'Update Failed');
            error.error.message.errors.map((err) => {
              this.toastr.error(err.message, 'Update Failed');
            });
          }
        );
      return;
    }
    this.tiersListService.addTier(this.organizerType, this.formData.value)
      .subscribe(
        (response) => {
          this.toastr.success('Success', 'Added successfully');
          this.tiersListService.getAllTiers(this.organizerType).subscribe((res: ResponseWrapper<TiersList>) => {
            this.tiersListData = res;
            this.tiersListMeta = res.meta;
            this.cdr.markForCheck();
          });
          this.showModal = false;
          this.cdr.markForCheck();
        },
        (error) => {
          this.toastr.error(error.error.message || error.message, 'Add Failed');
          error.error.message.errors.map((err) => {
            this.toastr.error(err.message, 'Add Failed');
          });
        }
      );
  }
}
