import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EntityType } from "app/shared/enums";
import { ToastrService } from "ngx-toastr";
import { NotificationsEntityModalContentComponent } from "../notifications-modal-content/notifications-entity-modal-content.component";
import { NotificationsService } from "../notifications.service";

@Component({
  selector: "app-notify",
  templateUrl: "./notify.component.html",
  styleUrls: ["./notify.component.scss"],
})
export class NotifyComponent implements OnInit {
  infoFormSubmitted: boolean = false;

  selectedAudience: { value: number; name: string };

  selectedEntity: { id: number; title: string };

  selectedEntityTitle: string;

  selectedAudienceEntityTitles: string[] = [];

  selectedEntityType: { value: EntityType; name: string };

  selectedAudienceEntityType: { value: EntityType; name: string };

  selectedAudienceEntities: { id: number; title: string }[] = [];

  audience = [];

  entityTypes = [
    { value: EntityType.EVENT, name: "Event" },
    { value: EntityType.COMMUNITY, name: "Community" },
    { value: EntityType.USER, name: "User" },
    { value: EntityType.TAG, name: "Tag" },
  ];

  notificationsForm = new FormGroup({
    title: new FormControl("", [Validators.required]),
    message: new FormControl(""),
    audience: new FormControl(null, [Validators.required]),
    audienceEntityId: new FormControl(),
    entityType: new FormControl(null, [Validators.required]),
    entityId: new FormControl(null, [Validators.required]),
  });

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private readonly notificationService: NotificationsService
  ) {}

  get cnf() {
    return this.notificationsForm.controls;
  }
  ngOnInit(): void {}

  onNotificationsFormSubmit() {
    this.infoFormSubmitted = true;
    if (this.cnf.invalid) {
      return;
    }
    const notification = this.notificationsForm.value;
    this.notificationService.sendNotification(notification).subscribe(
      (response) => {
        this.toastr.success("Successfully sent");
      },
      (err) => {
        this.toastr.error("Something went wrong");
      }
    );
  }

  onSelectedAudienceChange(event) {
    this.selectedAudience = event;

    this.notificationsForm.get("audience").patchValue(event.value);
  }

  onSelectedAudienceEntityTypeChange(event) {
    this.selectedAudienceEntityType = event;

    this.selectedAudienceEntities = [];
    this.selectedAudienceEntityTitles = [];
    this.selectedAudience = null;

    this.audience = this.notificationService.getAudienceByType(
      this.selectedAudienceEntityType.value
    );
  }

  onSelectedEntityTypeChange(event) {
    this.selectedEntityType = event;

    this.selectedEntity = null;
    this.selectedEntityTitle = null;

    this.notificationsForm.get("entityType").patchValue(event.value);
  }

  onSelectedEntityChange(event) {
    this.notificationsForm.get("entityId").patchValue(event.value);
  }

  async onEntityTypeDropDownOpen() {
    const modalRef = this.modalService.open(
      NotificationsEntityModalContentComponent,
      {
        size: "lg",
        centered: true,
      }
    );
    modalRef.componentInstance.entityType = this.selectedEntityType.value;

    this.selectedEntity = await modalRef.result;

    this.selectedEntityTitle = this.selectedEntity.title;
    this.notificationsForm.get("entityId").patchValue(this.selectedEntity.id);
  }

  async onAudienceEntityDropDownOpen() {
    const modalRef = this.modalService.open(
      NotificationsEntityModalContentComponent,
      {
        size: "lg",
        centered: true,
      }
    );
    modalRef.componentInstance.entityType =
      this.selectedAudienceEntityType.value;
    modalRef.componentInstance.selectType = "multi";

    this.selectedAudienceEntities = await modalRef.result;

    this.selectedAudienceEntityTitles = this.selectedAudienceEntities.map(
      (entity) => entity.title
    );

    this.notificationsForm
      .get("audienceEntityId")
      .patchValue(this.selectedAudienceEntities.map((entity) => entity.id));
  }

  onResetForm() {
    this.selectedAudienceEntities = [];
    this.selectedAudience = null;
    this.selectedEntityType = null;
    this.selectedAudienceEntityType = null;
  }
  onClearAudienceEntities() {
    this.selectedAudienceEntities = [];
    this.notificationsForm.get("audienceEntityId").patchValue([]);
  }
}
