import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenService } from "app/shared/auth/token.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { Community } from "../models/communities.model";
import { CommunityState } from "../../../../shared/enums/";
import ResponseWrapper from "app/shared/models/response.models";

@Injectable({
  providedIn: "root",
})
export class CommunityService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: this.tokenService.getToken(),
    };
    return new HttpHeaders(headersConfig);
  }

  getCommunities(organizerType?: string, options?: {
    page?: number;
    search?: string;
  }): Observable<ResponseWrapper<Community>> {
    const url = !organizerType || organizerType === 'freelancer' ? `dashboard/communities` : `dashboard/organization`;
    return this.httpClient.get<ResponseWrapper<Community>>(
      `${environment.apiUrl}/${url}`,
      {
        headers: this.setHeaders(),
        params: options,
      }
    );
  }

  getCommunity(communityId: number): Observable<Community> {
    return this.httpClient.get<Community>(
      `${environment.apiUrl}/dashboard/communities/${communityId}`,
      {
        headers: this.setHeaders(),
      }
    );
  }

  updateCommunity(path: string, body: Object = {}) {
    return this.httpClient.put(
      `${environment.apiUrl}/dashboard/communities/${path}`,
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }

  updateCommunityState(communityId: string, state: CommunityState) {
    return this.httpClient.patch(
      `${environment.apiUrl}/dashboard/communities/${communityId}/state`,
      { state },
      {
        headers: this.setHeaders(),
      }
    );
  }

  getCommunityRevenue(
    communityId: number,
    fromDate: string,
    toDate: string
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/dashboard/communities/${communityId}/revenue`,
      { startDate: fromDate, endDate: toDate },
      {
        headers: this.setHeaders(),
      }
    );
  }

  getCommunitiesRevenue(
    fromDate: string,
    toDate: string,
    page?: number
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/dashboard/communities/revenue?page=${page}`,
      { startDate: fromDate, endDate: toDate },
      {
        headers: this.setHeaders(),
      }
    );
  }
}
