import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenService } from "app/shared/auth/token.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { Charity } from "../models/charity.model";
import { CommunityState } from "../../../../shared/enums";
import ResponseWrapper from "app/shared/models/response.models";

@Injectable({
  providedIn: "root",
})
export class CharityService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: this.tokenService.getToken(),
    };
    return new HttpHeaders(headersConfig);
  }

  getCharities(options?: {
    page?: number;
    search?: string;
  }): Observable<ResponseWrapper<Charity[]>> {
    return this.httpClient.get<ResponseWrapper<Charity[]>>(
      `${environment.apiUrl}/dashboard/charity`,
      {
        headers: this.setHeaders(),
        params: options,
      }
    );
  }

  getCharity(charityId: number): Observable<Charity> {
    return this.httpClient.get<Charity>(
      `${environment.apiUrl}/dashboard/charity/${charityId}`,
      {
        headers: this.setHeaders(),
      }
    );
  }
  getCharityInvoices(charityId: number): Observable<Charity> {
    return this.httpClient.get<Charity>(
      `${environment.apiUrl}/dashboard/invoices/charity/${charityId}`,
      {
        headers: this.setHeaders(),
      }
    );
  }

  getCharitySubInvoices(
    charityId: number,
    options?: {
      page: number;
    }
  ): Observable<Charity> {
    return this.httpClient.get<Charity>(
      `${environment.apiUrl}/dashboard/invoices/${charityId}/charity-subinvoices`,
      {
        headers: this.setHeaders(),
        params: options,
      }
    );
  }
}
