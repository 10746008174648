import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import ResponseWrapper from "app/shared/models/response.models";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Community } from "../models/communities.model";
import { CommunityService } from "../service/community.service";
import { DatePipe } from "@angular/common";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ExcelService } from "app/shared/services/excel.service";

@Component({
  selector: "app-communities-revenue",
  templateUrl: "./communities-revenue.component.html",
  styleUrls: ["./communities-revenue.component.scss"],
})
export class CommunitiesRevenueComponent implements OnInit {
  page = 1;
  pageSize: number;
  dateRangeForm: FormGroup;
  minDate: string;
  loading: boolean = false;
  communitiesRevenue;
  totalRevenue: number;
  formattedFromDate: string;
  formattedToDate: string;
  from: string;
  to: string;

  constructor(
    private communityService: CommunityService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private excelService: ExcelService
  ) {
    const today = new Date();
    const startDate = new Date(2021, 0, 1);
    this.minDate = this.datePipe.transform(startDate, "yyyy-MM-dd");

    this.dateRangeForm = new FormGroup(
      {
        fromDate: new FormControl(startDate, Validators.required),
        toDate: new FormControl(today, Validators.required),
      }
      // { validators: maxThreeMonthRangeValidator() }
    );
  }

  onSubmit() {
    if (this.dateRangeForm.hasError("maxThreeMonthRange")) {
      this.toastr.error(
        "Date range should not exceed 3 months.",
        "Form Validation Error"
      );
      return;
    } else if (this.dateRangeForm.invalid) {
      this.toastr.error("Please select valid dates.", "Form Validation Error");
      return;
    }

    this.spinner.show();
    this.loading = true;

    const fromDate = this.dateRangeForm.get("fromDate").value;
    const toDate = this.dateRangeForm.get("toDate").value;

    this.formattedFromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd");
    this.formattedToDate = this.datePipe.transform(toDate, "yyyy-MM-dd");

    // Call the service method with the selected date range
    this.communityService
      .getCommunitiesRevenue(this.formattedFromDate, this.formattedToDate)
      .subscribe(
        (revenueData: any) => {
          this.spinner.hide();
          this.loading = false;
          this.communitiesRevenue = revenueData.data;
          this.from = this.datePipe.transform(revenueData.from, "yyyy-MM-dd");
          this.to = this.datePipe.transform(revenueData.to, "yyyy-MM-dd");
          this.pageSize = revenueData?.meta?.total;
          this.cdr.markForCheck();
          this.dateRangeForm.reset();
        },
        (error) => {
          this.spinner.hide();
          this.loading = false;
          console.error(error);
        }
      );
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.communitiesRevenue, "sample");
  }

  pageChange(pageNumber = 1) {
    this.spinner.show();
    this.communityService
      .getCommunitiesRevenue(
        this.formattedFromDate,
        this.formattedToDate,
        pageNumber
      )
      .subscribe((revenueData: ResponseWrapper<Community>) => {
        this.communitiesRevenue = revenueData.data;
        this.pageSize = revenueData?.meta?.total;
        this.cdr.markForCheck();
        this.spinner.hide();
      });
  }

  ngOnInit(): void {}
}
