<div class="row">
  <div class="col-12">
    <div class="content-header">{{ event?.title }}</div>
  </div>
</div>
<!-- Event Page starts -->
<div class="row justify-content-center">
  <div class="col-md-9">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="tab-content">
            <div class="media">
              <img
                [src]="
                  'https://ik.imagekit.io/tnc8pizf9e/' +
                  event?.featuredImage +
                  '?tr=w-800,h-200'
                "
                alt="profile-img"
              />
            </div>
            <hr class="mt-1 mt-sm-2" />
            <div>
              <div class="row">
                <div class="col-12">
                  <label>Title</label>
                  <p>{{ event?.title }}</p>
                </div>
                <div class="col-12">
                  <label>Description</label>
                  <p>{{ event?.description }}</p>
                </div>
                <div class="col-6">
                  <label>State</label>
                  <p>{{ event?.state }}</p>
                </div>
                <div class="col-6">
                  <label>Date</label>
                  <p>{{ event?.date }}</p>
                </div>
                <div class="col-6">
                  <label>Payment Method</label>
                  <p>
                    {{ event?.paymentMethod }}
                  </p>
                </div>
                <div class="col-6">
                  <label>Organizer</label>
                  <p>
                    {{ event?.organizer?.first_name }} {{ event?.organizer?.last_name }}
                  </p>
                </div>
                <div
                  class="col-12 d-flex flex-sm-row flex-column justify-content-end"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
