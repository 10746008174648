import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenService } from "app/shared/auth/token.service";
import ResponseWrapper from "app/shared/models/response.models";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { Tag } from "./models/tag.model";

@Injectable({
  providedIn: "root",
})
export class TagService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}
  private setHeaders(): HttpHeaders {
    let headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: this.tokenService.getToken(),
    };
    return new HttpHeaders(headersConfig);
  }

  getTags(options?: {
    page?: number;
    search?: string;
  }): Observable<ResponseWrapper<Tag>> {
    return this.httpClient.get<ResponseWrapper<Tag>>(
      `${environment.apiUrl}/dashboard/tags`,
      {
        headers: this.setHeaders(),
        params: options,
      }
    );
  }
}
